import React from 'react';
import './CreatedAddThingItem.css';

interface CreatedAddThingItemProps {
    thing: { id: number; title: string; description: string;};
    thingtype: { id: number; plural: string, singular: string, color: string, };
    onPlanThingClick: (thing: any) => void;
    selected: boolean;
}

const CreatedAddThingItem: React.FC<CreatedAddThingItemProps> = (props) => {
    return (
        <div className="created-addthing-item" style={{backgroundColor: props.thingtype.color}} onClick={() => props.onPlanThingClick(props.thing)}>
            { props.selected && <div className="selected-addthing-div">
                <span className="selected-addthing-icon-span material-symbols-rounded">check</span>
            </div>}
            <h2 className='created-addthing-title-h2'>{props.thing.title}</h2>
            <hr className="created-addthing-divder" />
            <p className='created-addthing-txt-p'>{props.thing.description}</p>
        </div>
    );
};

export default CreatedAddThingItem;