
const localEnv = false;
const devEnv = false;

export var apiURL = '';
export var backendURL = '';
export var webhookUrl = '';
export var returnWebsite = '';

if (localEnv) {
    apiURL = 'http://localhost:8000';
    backendURL = 'https://backend-dev.10-things.eu';
    webhookUrl = 'https://api.10-things.nl/mollie/webhook_verification/';
    returnWebsite = 'http://localhost:3000';
} else {
    if (devEnv) {
        apiURL = 'https://api.10-things.nl';
        backendURL = 'https://backend-dev.10-things.eu';
        webhookUrl = 'https://api.10-things.nl/mollie/webhook_verification/';
        returnWebsite = 'https://10-things.nl';
    } else {
        apiURL = 'https://api-dev.10-things.eu';
        backendURL = 'https://api-dev.10-things.nl';
        webhookUrl = 'https://api-dev.10-things.eu/mollie/webhook_verification/';
        returnWebsite = 'https://10-things.nl';
    }
}

console.log('API URL:', apiURL);
console.log('Backend URL:', backendURL);

