import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../../assets/img/website-10things-logo-v2-outlined.png';
import { Link } from 'react-router-dom';
import './CompanyInfo.css';
import { getCompanyDetails, searchCompany } from '../../../../api/kvk/kvkSerice';

interface CompanyInfoProps {
    step: number;
    setStep: (step: number) => void;
    companyName: string;
    setCompanyName: (companyName: string) => void;
    errorCompanyName: string;
    street: string;
    setStreet: (street: string) => void;
    errorStreet: string;
    houseNumber: string;
    setHouseNumber: (houseNumber: string) => void;
    errorHouseNumber: string;
    zipcode: string;
    setZipcode: (zipcode: string) => void;
    errorZipcode: string;
    city: string;
    setCity: (city: string) => void;
    errorCity: string;
    province: string;
    setProvince: (province: string) => void;
    errorProvince: string;
    country: string;
    setCountry: (country: string) => void;
    errorCountry: string;
    handleCompanyInfoValidation: (event: React.FormEvent<HTMLFormElement>) => void;
}

const CompanyInfo: React.FC<CompanyInfoProps> = (props) => {

    const handleBackClick = () => {
        props.setStep(props.step - 1);
    };

    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isLookingUp, setIsLookingUp] = useState(false);
    const parentDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (parentDivRef.current && !parentDivRef.current.contains(event.target as Node)) {
                setIsFocused(false);
                setIsLookingUp(false);
                setSearchResults([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearch = async () => {
        try {
            setError(null);
            const results = await searchCompany({ naam: companyLookingUp });
            console.log(results);
            setSearchResults(results.resultaten);
        } catch (err) {
            setError('Failed to fetch company data');
        }
    };

    const handleLookingUp = (value: string) => {
        setCompanyLookingUp(value);
        if (value.length > 2) {
            console.log('Looking up:', value);
            handleSearch();
            setIsLookingUp(true);
        } else {
            setSearchResults([]);
            setIsLookingUp(false);
        }
    };

    const [companyDetails, setCompanyDetails] = useState<any | null>(null);

    const handleCompanySelect = async (kvk: string) => {
        console.log('Selected company:', kvk);
        try {
            const results = await getCompanyDetails(kvk);
            console.log(results);
            setCompanyDetails(results.adressen[0]);
            props.setCompanyName(results.eersteHandelsnaam);
            props.setStreet(results.adressen[0].straatnaam);
            props.setHouseNumber(`${results.adressen[0].huisnummer} ${results.adressen[0].huisnummerToevoeging}`);
            props.setZipcode(results.adressen[0].postcode);
            props.setCity(results.adressen[0].plaats);
            props.setCountry(results.adressen[0].land);
            setSearchResults([]);
            setIsLookingUp(false);
            setCompanyLookingUp('');
        } catch (err) {
            setError('Failed to fetch company data');
        }
    }

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value.length > 2) {
            setIsLookingUp(true);
        } else {
            setIsLookingUp(false);
        }
        setIsFocused(true);
    };

    const [companyLookingUp, setCompanyLookingUp] = useState('');



    return (
        <div className='company-info-content-div'>
            <nav className="register-top-nav">
                <ul className="register-top-nav-listing-ul">
                    <li className="register-top-left-area-li">
                        <Link to="/">
                            <button className="register-back-btn" type='button'>
                                <span className="register-back-btn-icon-span material-symbols-outlined">
                                    arrow_back
                                </span>
                            </button>
                        </Link>
                    </li>
                    <li className="register-top-middle-area">
                        <img src={logo} alt="10-Things" className="register-logo-img" />
                    </li>
                    <li className="register-top-right-area-li">
                    </li>
                </ul>
            </nav>
            <div className="register-inner-wrapper-div">
                <div className="register-inner-container-div">
                    <h1 className="register-title-h1">Maak hier je account aan</h1>
                    <p className="register-txt-p">Ben je klaar om je zaak naar het volgende niveau te tillen?<br /> Begin hier met een proefperiode van 7 dagen! </p>
                    {/* <div className={`register-company-dropdown-div ${isLookingUp ? 'open' : ''}`} >
                        <input type="text" className="register-company-input" value={companyLookingUp} onChange={(event) => handleLookingUp(event.target.value)} onFocus={handleFocus} />
                        <div className="register-company-dropdown-content">
                            <ul className="register-company-dropdown-content-listing-ul">
                                {searchResults.map((result, index) => (
                                    <li key={index} className="register-company-dropdown-content-item-li" onClick={() => handleCompanySelect(result.kvkNummer)}>
                                        <p className="register-company-dropdown-content-item-p">{result.naam}</p>
                                        <p className="register-company-dropdown-under-content-txt-p">{result.kvkNummer} {result.adres.binnenlandsAdres ? '•' : null} {result.adres.binnenlandsAdres ? result.adres.binnenlandsAdres.straatnaam : null} {result.adres.binnenlandsAdres ? result.adres.binnenlandsAdres.plaats : null}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div> */}
                    <form className="register-company-info-form" onSubmit={props.handleCompanyInfoValidation}>
                        <div className="register-company-info-overview-div">
                            <div className="register-company-info-overview-item-div">
                                <label htmlFor="companyname" className="register-label">Bedrijfsnaam<span className="register-required-span">*</span></label>
                                <input type="text" placeholder="Vul je bedrijfsnaam in" name='companyname' value={props.companyName} onChange={(event) => props.setCompanyName(event.target.value)} className="register-input" />
                                {props.errorCompanyName ? <p className="register-err-txt-p">{props.errorCompanyName}</p> : null}
                            </div>
                            <div className="register-company-info-overview-item-div">
                                <div className="register-company-info-left-area housenumber">
                                    <label htmlFor="street" className="register-label">Straatnaam<span className="register-required-span">*</span></label>
                                    <input type="text" placeholder="Vul je adres in" name='street' value={props.street} onChange={(event) => props.setStreet(event.target.value)} className="register-input" />
                                    {props.errorStreet ? <p className="register-err-txt-p">{props.errorStreet}</p> : null}
                                </div>
                                <div className="register-company-info-right-area housenumber">
                                    <label htmlFor="housenumber" className="register-label">Huisnummer<span className="register-required-span">*</span></label>
                                    <input type="text" placeholder="Vul je huisnummer in" name='housenumber' value={props.houseNumber} onChange={(event) => props.setHouseNumber(event.target.value)} className="register-input" />
                                    {props.errorHouseNumber ? <p className="register-err-txt-p">{props.errorHouseNumber}</p> : null}
                                </div>
                            </div>
                            <div className="register-company-info-overview-item-div">
                                <div className="register-company-info-left-area">
                                    <label htmlFor="zipcode" className="register-label">Postcode<span className="register-required-span">*</span></label>
                                    <input type="text" placeholder="Vul je postcode in" name='zipcode' value={props.zipcode} onChange={(event) => props.setZipcode(event.target.value)} className="register-input" />
                                    {props.errorZipcode ? <p className="register-err-txt-p">{props.errorZipcode}</p> : null}
                                </div>
                                <div className="register-company-info-right-area">
                                    <label htmlFor="city" className="register-label">Plaatsnaam<span className="register-required-span">*</span></label>
                                    <input type="text" placeholder="Vul je plaatsnaam in" name='city' value={props.city} onChange={(event) => props.setCity(event.target.value)} className="register-input" />
                                    {props.errorCity ? <p className="register-err-txt-p">{props.errorCity}</p> : null}
                                </div>
                            </div>
                            <div className="register-company-info-overview-item-div">
                                <label htmlFor="province" className="register-label">Provincie<span className="register-required-span">*</span></label>
                                <input type="text" placeholder="Vul je provincie in" name='province' value={props.province} onChange={(event) => props.setProvince(event.target.value)} className="register-input" />
                                {props.errorProvince ? <p className="register-err-txt-p">{props.errorProvince}</p> : null}
                            </div>
                            <div className="register-company-info-overview-item-div">
                                <label htmlFor="country" className="register-label">Land<span className="register-required-span">*</span></label>
                                <input type="text" placeholder="Vul je land in" name='country' value={props.country} onChange={(event) => props.setCountry(event.target.value)} className="register-input" />
                                {props.errorCountry ? <p className="register-err-txt-p">{props.errorCountry}</p> : null}
                            </div>
                            <button className="register-next-btn" type='submit'>Volgende</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CompanyInfo;