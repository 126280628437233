import { useEffect, useState } from 'react';
import { InspireMe } from '../backend/InspireMe/InspireMeService';
import { fetchActionsByDate, deleteDBAction } from '../api/actions/actionsService';
import { getSubsidiary, getUserInfo } from '../api/auth/user-info';
import { Action } from '../models/Action';
import { InspireMeOutput } from '../models/InspireMe';
import { user } from '@nextui-org/react';

const useInspireMe = (selected_labels: any, thingtype_id: number, max: number) => {
    const [date, setDate] = useState<Date>(new Date());
    const [actions, setActions] = useState<Action[]>([]);
    const [inspiration, setInspiration] = useState<InspireMeOutput[]>([]);
    const [unplannedActionIds, setUnplannedActionIds] = useState<number[]>([]);
    const [subsidiary, setSubsidiary] = useState<any>(null);
    const [userInfo, setUserInfo] = useState<any>(null);

    // 1. Gegevens ophalen subsidiary en user info
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                console.log('Fetching subsidiary and user info...');
                const subsidiaryData = await getSubsidiary();
                const userInfoData = await getUserInfo();
                setSubsidiary(subsidiaryData);
                setUserInfo(userInfoData);
                console.log('Subsidiary data:', subsidiaryData);
                console.log('User info data:', userInfoData);
            } catch (err) {
                console.error('Failed to fetch user data:', err);
            }
        };

        fetchUserData();
    }, []);

    // 2. Fetch actions by date
    useEffect(() => {
        const loadActions = async () => {
            try {
                const formattedDate = date.toISOString().slice(0, 10);
                console.log('Fetching actions for date:', formattedDate);
                const actionsData = await fetchActionsByDate(formattedDate);
                setActions(actionsData);
                console.log('Actions data:', actionsData);
            } catch (err) {
                console.error('Failed to fetch actions:', err);
            }
        };

        if (subsidiary && userInfo) {
            loadActions();
        }
    }, [date, subsidiary, userInfo]);

    const getPlannedActionIds = (actions: any[], thingTypeId: number): number[] => {
        // Controleer of actions een array is
        if (!Array.isArray(actions)) {
            console.error('getPlannedActionIds: actions is not an array');
            return [];
        }

        return actions
            .filter(
                (action) =>
                    action.is_planned === true &&
                    action.thing_type.id === thingTypeId
            )
            .map((action) => action.thing_id);
    };

    // 6. De inspire me functie vult het aantal things max aan tot 10 (en haalt geplande things uit de inspire me output ivm dubbelen)
    const updateThingTypesMax = (thingTypes: { id: number, max: number }[], actions: any[]) => {
        return thingTypes.map((thingType) => {
            const plannedActionIds = getPlannedActionIds(actions, thingType.id);
            const maxThings = thingType.max - plannedActionIds.length;
            console.log(`ThingType ID: ${thingType.id}, Planned Action IDs: ${plannedActionIds}, Max Things: ${maxThings}`);
            return {
                ...thingType,
                max: maxThings
            };
        });
    };

    useEffect(() => {
        const processActions = async () => {
            if (!subsidiary || !userInfo) return;

            // 3. Thing types definiëren
            const thingTypes = [
                { id: thingtype_id, max: max }
                // { id: 3, max: 2 },
                // { id: 4, max: 2 },
                // { id: 5, max: 2 },
            ];

            console.log('Thing types before update:', thingTypes);
            const updatedThingTypes = updateThingTypesMax(thingTypes, actions);
            console.log('Updated thing types:', updatedThingTypes);

            const plannedThingIds = actions
                .filter((action) => action.is_planned === true)
                .map((action) => action.thing_id);
            console.log('Planned thing IDs:', plannedThingIds);

            const unplannedActionIds = actions
                .filter((action) => action.is_planned === false)
                .map((action) => action.id);
            setUnplannedActionIds(unplannedActionIds);
            console.log('Unplanned action IDs:', unplannedActionIds);

            // 7. Inspire me functie output in de things toevoegen
            console.log('Calling InspireMe with:', {
                'selected_labels': selected_labels,
                'thing_types': updatedThingTypes,
                'planned_things': plannedThingIds,
                'subsidiary_id': subsidiary.id,
                'user_id': userInfo.id,
            });
            const response = await InspireMe({
                'selected_labels': selected_labels,
                'thing_types': updatedThingTypes,
                'planned_things': [],
                'subsidiary_id': subsidiary.id,
                'user_id': userInfo.id,
            });

            setInspiration(response);
            console.log('Inspiration response:', response);
        };

        // if (actions.length > 0) {
            processActions();
        // }
    }, [thingtype_id, max, selected_labels, userInfo, subsidiary, actions]);

    return inspiration as InspireMeOutput[];
};

export default useInspireMe;
