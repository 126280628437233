import React, { useEffect } from "react";
import "./SendReportModal.css";
import { Input, Textarea } from "@nextui-org/react";
import { addReport, addReportNotes, addReportThings, fetchReportKPIs } from "../../../../api/day_reports/day_reportsService";
import { getSubsidiary } from "../../../../api/auth/user-info";

interface SendReportModalProps {
    onClose: () => void;
    reportKPIs: any[];
    notes: any[];
    actions: any[];
}

const SendReportModal: React.FC<SendReportModalProps> = (props) => {

    const [kpiValues, setKpiValues] = React.useState<{ [kpi: string]: string }>({});
    const [kpiErrors, setKpiErrors] = React.useState<{ [kpi: string]: boolean }>({});
    const [kpiErrorMSG, setKpiErrorMSG] = React.useState<{ [kpi: string]: string }>({});

    const [leidingGevende, setLeidingGevende] = React.useState<string>('');
    const [leidingGevendeError, setLeidingGevendeError] = React.useState<boolean>(false);
    const [leidingGevendeErrorMSG, setLeidingGevendeErrorMSG] = React.useState<string>('');
    const [grade, setGrade] = React.useState<string>('');
    const [gradeError, setGradeError] = React.useState<boolean>(false);
    const [gradeErrorMSG, setGradeErrorMSG] = React.useState<string>('');
    const [finalreport, setFinalReport] = React.useState<string>('');
    const [finalReportError, setFinalReportError] = React.useState<boolean>(false);
    const [finalReportErrorMSG, setFinalReportErrorMSG] = React.useState<string>('');
    const [couverts, setCouverts] = React.useState<string>('');
    const [couvertsError, setCouvertsError] = React.useState<boolean>(false);
    const [couvertsErrorMSG, setCouvertsErrorMSG] = React.useState<string>('');

    const submitForm = async (event: React.FormEvent) => {
        event.preventDefault();

        let formError = false;

        if (leidingGevende === '') {
            setLeidingGevendeError(true);
            setLeidingGevendeErrorMSG('Naam leidinggevende is verplicht');
            formError = true;
        } else {
            setLeidingGevendeError(false);
            setLeidingGevendeErrorMSG('');
        }

        if (grade === '') {
            setGradeError(true);
            setGradeErrorMSG('Cijfer is verplicht');
            formError = true;
        } else {
            setGradeError(false);
            setGradeErrorMSG('');
        }

        if (couverts === '') {
            setCouvertsError(true);
            setCouvertsErrorMSG('Aantal couverts is verplicht');
            formError = true;
        } else {
            setCouvertsError(false);
            setCouvertsErrorMSG('');
        }
        props.reportKPIs.forEach((kpi) => {
            if (kpiValues[kpi.id] === '' || kpiValues[kpi.id] === undefined) {
                setKpiValues((prevKpiValues) => ({ ...prevKpiValues, [kpi.id]: '' }));
                setKpiErrors((prevKpiError) => ({ ...prevKpiError, [kpi.id]: true }));
                setKpiErrorMSG((prevKpiErrorMSG) => ({ ...prevKpiErrorMSG, [kpi.id]: kpi.label + ' is verplicht!' }));
                formError = true;
            } else {
                setKpiErrors((prevKpiError) => ({ ...prevKpiError, [kpi.id]: false }));
                setKpiErrorMSG((prevKpiErrorMSG) => ({ ...prevKpiErrorMSG, [kpi.id]: '' }));
            }
        });

        if (finalreport === '') {
            setFinalReportError(true);
            setFinalReportErrorMSG('Rapportage is verplicht');
            formError = true;
        } else {
            setFinalReportError(false);
            setFinalReportErrorMSG('');
        }

        if(formError === false) {
            console.log('Form submitted');
            const subsidiaryID = getSubsidiary().id;
            const response = await addReport({
                subsidiary_id: subsidiaryID,
                manager: leidingGevende,
                grade: Number(grade),
                final_report: finalreport,
                date: new Date().toISOString().slice(0, 10),
                cv: Number(couverts),
                additional_inputs: JSON.stringify(kpiValues)
            });

            const report_id = response.id;

            props.notes.forEach(async (note) => {
                await addReportNotes({
                    report_id: report_id,
                    note_txt: note.note_txt,
                    note_date: note.last_update_at,
                });
            });

            props.actions.forEach(async (action) => {
                await addReportThings({
                    thing_type_id: action.thing_type_id,
                    report_id: report_id,
                    title: action.title,
                    description: action.description,
                    comments: action.comments,
                    done_date: action.completed_on
                });
            });

            props.onClose();

            console.log('Response:', response);
        }
    };

    useEffect(() => {
        console.log('KPIValues: ', kpiValues);
    }, [kpiValues]);
    
    useEffect(() => {
        console.log('KPIErrors: ', kpiErrors);
    }, [kpiErrors]);
    
    useEffect(() => {
        console.log('KPIErrorMSG: ', kpiErrorMSG);
    }, [kpiErrorMSG]);

    return (
        <div>
            <form className="sendreport-form" onSubmit={submitForm}>
                <Input className='leidinggevende-input' type="text" label="Naam Leidinggevende" value={leidingGevende} variant='bordered' isInvalid={leidingGevendeError} errorMessage={leidingGevendeErrorMSG} onChange={(event) => setLeidingGevende(event.target.value)} />
                <Input className='cijfer-input' type="number" label="Cijfer" value={grade} variant='bordered' isInvalid={gradeError} errorMessage={gradeErrorMSG} onChange={(event) => setGrade(event.target.value)} />
                <Input className="couverts-input" type="number" label="Aantal couverts" value={couverts} variant='bordered' isInvalid={couvertsError} errorMessage={couvertsErrorMSG} onChange={(event) => setCouverts(event.target.value)} />
                {props.reportKPIs.map((kpi, index) => (
                    <Input
                        key={index}
                        className='kpi-input'
                        type="text"
                        label={kpi.label}
                        value={kpiValues[kpi.id] || ''}
                        variant='bordered'
                        onChange={(event) => {
                            console.log('onChange event:', event);
                            console.log('event.target.value:', event.target.value);
                            setKpiValues({ ...kpiValues, [kpi.id]: event.target.value });
                        }}
                        isInvalid={kpiErrors[kpi.id]}
                        errorMessage={kpiErrorMSG[kpi.id]}
                    />
                ))}
                <Textarea
                    className='finalreport-input'
                    variant='bordered'
                    label="Rapportage"
                    value={finalreport}
                    isInvalid={finalReportError}
                    errorMessage={finalReportErrorMSG}
                    onChange={(event) => setFinalReport(event.target.value)}
                    minRows={10}
                />
                <div className="sendreport-controls-area">
                    <button className="sendreport-submit-button" type="submit">Verstuur</button>
                    <button className="sendreport-cancel-button" type="button" onClick={props.onClose}>Annuleren</button>
                </div>
            </form>
        </div>
    );
};

export default SendReportModal;