import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/img/website-10things-logo-v2-outlined.png';

import './NavBar.css';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';

const NavBar: React.FC = () => {
    return (
        <nav className="mainsite-nav">
            <ul className="main-site-listing-ul">
                <li className="main-site-logo-li">
                    <Link to="/" className="main-site-logo-link">
                    <img src={logo} alt="10-Things" className="main-site-logo-img" />
                    </Link>
                </li>
                <li className="main-site-inner-nav-li">
                    <nav className="main-site-inner-nav">
                        <ul className="main-site-inner-nav-listing-ul">
                            <Link to="/#pricing" className="main-site-inner-nav-item-li">
                            <li className="main-site-inner-nav-item-li">
                                <p className="main-site-inner-nav-txt-p">Prijzen overzicht</p>
                            </li>
                            </Link>
                            <Link to="/register" className="main-site-inner-nav-item-li">
                            <li className="main-site-inner-nav-item-li">
                                <p className="main-site-inner-nav-txt-p">Probeer het gratis!</p>
                            </li>
                            </Link>
                            <Link to="/contact" className="main-site-inner-nav-item-li">
                            <li className="main-site-inner-nav-item-li">
                                <p className="main-site-inner-nav-txt-p">Contact</p>
                            </li>
                            </Link>
                        </ul>
                    </nav>
                </li>
                <li className="main-site-quick-actions-li">
                    <Link to="/login" className="main-site-quick-action-btn">
                    <button className="main-site-quick-action-btn">
                        Inloggen naar de app
                    </button>
                    </Link>
                </li>
                <li className="main-site-mobile-menu-li">
                    <HamburgerMenu />
                </li>
            </ul>
        </nav>
    );
};

export default NavBar;