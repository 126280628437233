import React, { useRef, useState } from 'react';
import logo from '../../assets/img/website-10things-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import './ForgotPasswordView.css';
import CheckEmail from './InnerViews/CheckEmail/CheckEmail';
import Verification from './InnerViews/Verification/Verification';
import { checkUserExists, forgotPassword, resendConfirmationCode, resetPassword } from '../../api/users/usersService';
import ChangePassword from './InnerViews/ChangePassword/ChangePassword';
import usePageTracking from '../../utils/usePageTracking';
import { Helmet } from 'react-helmet-async';

const ForgotPasswordView: React.FC = () => {
    usePageTracking();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleForgotPasswordValidation = async () => {
        console.log('Forgot password validation');

        let error = false;

        if (email === '') {
            setEmailError('Email is verplicht');
            error = true;
        } else if (!emailRegex.test(email)) {
            setEmailError('Ongeldig emailadres');
            error = true;
        } else {
            setEmailError('');
        }

        if (!error) {
            await checkUserExists(email).then((response) => {
                if (response.data) {
                    setEmailError('');
                    setStep(2);
                } else {
                    setEmailError('E-mail is niet bekend bij ons');

                }
            }
            );

        }
    }

    const [verificationCode, setVerificationCode] = useState<string[]>(['', '', '', '', '', '']);
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const [verificationError, setVerificationError] = useState('');
    const [userName, setUserName] = React.useState('');

    const handleChange = (index: number, value: string) => {
        const updatedVerificationCode = [...verificationCode];
        updatedVerificationCode[index] = value;
        setVerificationCode(updatedVerificationCode);

        // Move to the next input if the current one is filled
        if (value && index < 5) {
            inputsRef.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !verificationCode[index] && index > 0) {
            inputsRef.current[index - 1]?.focus();
        }
    };

    const handleVerification = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('Verification code:', verificationCode.join(''));
        let error = false;

        if (verificationCode.join('').length < 6) {
            setVerificationError('Vul alle velden in!');
            error = true;
        } else {
            setVerificationError('');
        }

        // if (!error) {
        //     console.log('Verification code:', verificationCode.join(''));
        //     try {
        //         const response = await verifyUser(userName, verificationCode.join(''));
        //         console.log(response);
        //     } catch (error: any) {
        //         if (error.response && error.response.status === 404) {
        //             setVerificationError('Verificatie code is ongeldig');
        //         } else {
        //             console.error('Failed to verify user:', error);
        //             setVerificationError('Er is iets fout gegaan');
        //         }
        //     } finally {
        //         navigate('/login');
        //     }
        // }
    };

    const handleResendConfirmationCode = async () => {
        console.log(userName);
        try {
            console.log('Resending confirmation code...');
            const response = await resendConfirmationCode(userName);
            console.log(response);
        } catch (error) {
            console.error('Failed to resend confirmation code:', error);
        } finally {
            console.log('Confirmation code resent');
        }
    }

    const verificationProps = {
        verificationCode,
        setVerificationCode,
        inputsRef,
        handleChange,
        handleKeyDown,
        handleVerification,
        verificationError,
        handleResendConfirmationCode
    };

    const handleVerificationSubmit = async () => {
        let error = false;

        if (verificationCode.join('').length < 6) {
            setVerificationError('Vul alle velden in!');
            error = true;
        } else {
            setVerificationError('');
        }

        if (!error) {
            await resetPassword(email, { new_password: newPassword, confirmation_code: verificationCode.join('') }).then((response) => {
                navigate('/login');
            });
        }
    };

    const [newPassword, setNewPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

    const changePasswordProps = {
        newPassword,
        setNewPassword,
        errorPassword,
        confirmPassword,
        setConfirmPassword,
        errorConfirmPassword
    };

    const handleChangePasswordSubmit = async () => {
        let error = false;

        if (newPassword === '') {
            setErrorPassword('Wachtwoord is verplicht');
            error = true;
        } else if (newPassword.length < 12) {
            setErrorPassword('Wachtwoord moet minimaal 12 karakters bevatten');
            error = true;
        } else if (!/(?=.*?[0-9])/.test(newPassword)) {
            setErrorPassword('Wachtwoord moet minimaal 1 nummer bevatten');
            error = true;
        } else if (!/(?=.*?[!@#\$&*~])/.test(newPassword)) {
            setErrorPassword('Wachtwoord moet minimaal 1 speciaal karakter bevatten');
            error = true;
        } else if (!/(?=.*?[A-Z])/.test(newPassword)) {
            setErrorPassword('Wachtwoord moet minimaal 1 hoofdletter bevatten');
            error = true;
        } else if (!/(?=.*?[a-z])/.test(newPassword)) {
            setErrorPassword('Wachtwoord moet minimaal 1 kleine letter bevatten');
            error = true;
        } else {
            setErrorPassword('');
        }

        if (confirmPassword === '') {
            setErrorConfirmPassword('Bevestig wachtwoord is verplicht');
            error = true;
        } else if (newPassword !== confirmPassword) {
            setErrorConfirmPassword('Wachtwoorden komen niet overeen');
            error = true;
        } else {
            setErrorConfirmPassword('');
        }

        if (!error) {
            await forgotPassword(email).then((response) => {
                if (response) {
                    setStep(3);
                }
            });
        }

    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        switch (step) {
            case 1:
                handleForgotPasswordValidation();
                break;
            case 2:
                handleChangePasswordSubmit();
                break;
            case 3:
                handleVerificationSubmit();
                break;
        }
    }

    return (
        <div className="forgot-password-view">
            <Helmet>
                <title>10-Things | Wachtwoord vergeten</title>
            </Helmet>
            <Link to="/login">
                <button className="forgot-password-butt">
                    <span className="forgot-password-icon-span material-symbols-outlined">arrow_back</span>
                </button>
            </Link>
            <div className="forgot-password-wrapper">
                <div className="forgot-password-inner-page">
                    <div className="forgot-password-view-div">
                        <div className="forgot-password-logo-area">
                            <img src={logo} className="forgot-password-logo-img" />
                        </div>
                        <div className="forgot-password-form-area-div">
                            <form className="forgot-password-form" onSubmit={handleSubmit}>
                                {step == 1 && <CheckEmail email={email} setEmail={setEmail} emailError={emailError} />}
                                {step == 2 && <ChangePassword {...changePasswordProps} />}
                                {step == 3 && <Verification {...verificationProps} />}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordView;