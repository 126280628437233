import React from 'react';
import './DeleteAccount.css';
import { deleteUser } from '../../../../../../api/auth/user.auth';

const DeleteAccount: React.FC = () => {
    // Add your component logic here

    const handleDeleteAccount = () => {
        deleteUser();
    };

    return (
        <div>
            <p className='deleteaccount-txt-p'><b>Let op:</b> Het verwijderen van je account is permanent en kan niet ongedaan worden gemaakt. Al je gegevens, inclusief je profiel, berichten en instellingen, zullen voorgoed worden verwijderd.</p>
            <ul className='deleteaccount-listing-ul'>
                <li>
                    Je verliest toegang tot alle services en gegevens die gekoppeld zijn aan dit account.
                </li>
                <li>Je kunt deze beslissing niet terugdraaien.</li>
            </ul>
            <p className='deleteaccount-txt-p'>Als je zeker weet dat je je account wilt verwijderen, klik dan op "Verwijderen".</p>
            <button className="delete-account-btn" type='button' onClick={handleDeleteAccount}>
                Verwijder account
            </button>
        </div>
    );
};

export default DeleteAccount;