import React from 'react';
import './AddThingItem.css';
import { ThingType } from '../../../../../models/ThingType';

interface AddThingItemProps {
    thingtype: ThingType;
    onAddThingClick: () => void;
}

const AddThingItem: React.FC<AddThingItemProps> = ({thingtype, onAddThingClick}) => {
    // Add your component logic here

    return (
        <div className="add-thing-item" style={{ backgroundColor: thingtype.color }} onClick={onAddThingClick}>
            <span className="add-thing-icon-span material-symbols-outlined">add</span>
        </div>
    );
};

export default AddThingItem;