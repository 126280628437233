import React, { useState } from 'react';
import './PaymentMethodView.css';
import ideal from '../../../../assets/issuers/ideal/ideal.png';
import creditcard from '../../../../assets/issuers/creditcard/creditcard.png';
import { createPayment } from '../../../../api/mollie/mollieServices';
import useMollie from '../../../../utils/useMollie';
import { addTransaction } from '../../../../api/transactions/transactionsService';
import { getSubsidiary, getUserInfo } from '../../../../api/auth/user-info';
import { returnWebsite, webhookUrl } from '../../../../devEnv';

declare global {
    interface Window {
        Mollie: any;
    }
}

const TransactionStatus = {
    PAID: 1,
    PENDING: 2,
    OPEN: 3,
    CANCELLED: 4,
    REFUNDED: 5
};

interface PaymentMethodViewProps {
    // Add any props you need for the component here
    methods: any;
    transactionId: string;
    amount: number;
    customerID: string;
    productID: number;
}

const PaymentMethodView: React.FC<PaymentMethodViewProps> = (props) => {
    // Add your component logic here

    const verificationAmount = 0.01;

    const subsidiary = getSubsidiary();
    const user = getUserInfo();

    const [mollie, setMollie] = useState<any>(null);
    let amountInEuro = verificationAmount.toLocaleString('de-DE', {
        minimumFractionDigits: 2, // Adjust as needed for decimal places
        maximumFractionDigits: 2, // Adjust as needed for decimal places
    });

    const [selectedMethod, setSelectedMethod] = React.useState<string>('');

    const selectMethod = (method: string) => {
        setSelectedMethod(method);
    }

    const [cardNumberError, setCardNumberError] = React.useState<string>('');
    const [cardHolderError, setCardHolderError] = React.useState<string>('');
    const [expiryDateError, setExpiryDateError] = React.useState<string>('');
    const [verificationCodeError, setVerificationCodeError] = React.useState<string>('');

    // const [cardNumber, setCardNumber] = useState<any>(null);
    // const [cardHolder, setCardHolder] = useState<any>(null);
    // const [expiryDate, setExpiryDate] = useState<any>(null);
    // const [verificationCode, setVerificationCode] = useState<any>(null);

    const profileId = 'pfl_MZUu425JSH';

    useMollie(profileId, (mollie) => {
        setMollie(mollie);
        console.log('Mollie exists here');
        console.log(mollie);

        const options = {
            styles: {
                base: {
                    color: 'rgba(0, 0, 0, 0.8)',
                    '::placeholder': {
                        color: 'rgba(41, 58, 81)',
                    },
                },
            },
        };

        try {
            const cardNumber = mollie.createComponent('cardNumber', options);
            cardNumber.mount('#card-number');

            cardNumber.addEventListener('change', (event: any) => {
                if (event.error && event.touched) {
                    setCardNumberError(event.error);
                } else {
                    setCardNumberError('');
                }
            });

            const cardHolder = mollie.createComponent('cardHolder', options);
            cardHolder.mount('#card-holder');

            cardHolder.addEventListener('change', (event: any) => {
                if (event.error && event.touched) {
                    setCardHolderError(event.error);
                } else {
                    setCardHolderError('');
                }
            });

            const expiryDate = mollie.createComponent('expiryDate', options);
            expiryDate.mount('#expiry-date');

            expiryDate.addEventListener('change', (event: any) => {
                if (event.error && event.touched) {
                    setExpiryDateError(event.error);
                } else {
                    setExpiryDateError('');
                }
            });

            const verificationCode = mollie.createComponent('verificationCode', options);
            verificationCode.mount('#verification-code');

            verificationCode.addEventListener('change', (event: any) => {
                if (event.error && event.touched) {
                    setVerificationCodeError(event.error);
                } else {
                    setVerificationCodeError('');
                }
            });

            // Clean up when component unmounts
            return () => {
                cardNumber.unmount();
                cardHolder.unmount();
                expiryDate.unmount();
                verificationCode.unmount();
            };
        } catch (error) {
            console.error('Error creating Mollie components:', error);
        }
    }, []);

    const handleCreditCardSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const { token, error } = await mollie.createToken();
            if (error) {
                console.error('Error creating token:', error);
                // Handle the error accordingly
            } else {
                console.log('Card token created:', token);
                // Use the token for further processing
            }
        } catch (error) {
            console.error('Error creating token:', error);
        }
    }

    const [issuerError, setIssuerError] = React.useState<string>('');
 
    const handleiDealSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const selectedRadio = document.querySelector('input[name="issuer"]:checked');
        if (!selectedRadio) {
            // Handle form validation error
            setIssuerError('Selecteer een bank');
        } else {
            setIssuerError('');
            const issuerId = (selectedRadio as HTMLInputElement).value;
            const response = await createPayment({
                'amount': verificationAmount.toString(),
                'description': 'Verificatie 10-Things',
                'webhook_url': webhookUrl,
                'redirect_url': `${returnWebsite}/betaald?id=${props.transactionId}`,
                'cancel_url': `${returnWebsite}/betaling-geannuleerd?id=${props.transactionId}`,
                'method': 'ideal',
                'metaData': {
                    'order_id': props.transactionId,
                    'product_id': props.productID,
                    'is_recurring': true,
                    'subsidiary_id': subsidiary.id,
                    'parent_company_id': subsidiary.parent_company_id,
                    'user_id': user.id,
                    'customer_id': props.customerID,
                    'webhook_url': webhookUrl,
                },
                'issuer': issuerId,
                'sequenceType': 'first',
                'customer_id': props.customerID,
            });

            const addedTransaction = await addTransaction({
                'transaction_identifier': props.transactionId,
                'mollie_transaction_id': response.id,
                'status': TransactionStatus.PENDING,
                'amount': verificationAmount,
                'product_id': props.productID,
                'parent_company_id': subsidiary.parent_company_id,
            });

            console.log('Transaction added:', addedTransaction);

            const redirectLink = response._links.checkout.href;
            window.location.href = redirectLink;
        }
    }


    // useEffect(() => {
    //     const mollie = window.Mollie('pfl_MZUu425JSH', {
    //         locale: 'en_US',
    //     });




    // }, [methods]);


    const [selectedIssuerId, setSelectedIssuerId] = useState<string | null>(null);

    const handleIssuerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedIssuerId(event.target.value);
    };



    return (
        <div>
            <h1 className="payment-info-title-h1">Betalingsmethode</h1>

            <div className={`payment-method-input-div ideal ${selectedMethod === 'ideal' ? 'active' : ''}`}>
                <label htmlFor="ideal-option" className="payment-method-label" onClick={() => selectMethod('ideal')}>
                    <div className="payment-pay-method-inner-bar">
                        <div className="payment-pay-method-check-area">
                            <label className="radio">
                                <input type="radio" name="payment-method" value="ideal" id='ideal-option' />
                                <span className="radio-btn"></span>
                            </label>
                        </div>
                        <div className="payment-method-title-area">
                            <p className="payment-method-title-p">iDeal</p>
                            <img src={ideal} alt="iDeal" className="payment-method-issuers-img" />
                        </div>
                    </div>

                    <div className="payment-method-content-div">
                        <form className="payment-method-form ideal" onSubmit={handleiDealSubmit}>
                            <ul className="payment-method-issuers-lising-ul ideal">
                                {props.methods[0].issuers.map((issuer: any) => (
                                    <li key={issuer.id} className={`payment-method-issuer-item-li ${selectedIssuerId === issuer.id ? 'selected' : ''}`}>
                                        <label className="payment-method-issuer-label">
                                            <img src={issuer.image.size1x} alt={issuer.name} className="payment-method-issuer-img" />
                                            <input type="radio" name="issuer" value={issuer.id} className="invisible-radio ideal-issuer-radio-input" onChange={handleIssuerChange} checked={selectedIssuerId === issuer.id} />
                                            <p className="payment-method-issuer-txt-p">{issuer.name}</p>
                                        </label>
                                    </li>
                                ))}
                            </ul>
                            {issuerError !== '' ? <div id="issuer-error" className="form-field-error">{issuerError}</div> : null}
                            <button className="payment-method-submit-butt" type='submit'>
                                <span className="payment-method-submit-butt-txt-span">Betaal € {amountInEuro} ter verificatie</span>
                                <span className="payment-method-submit-butt-icon-span material-symbols-rounded">arrow_forward</span>
                            </button>
                        </form>
                    </div>

                </label>
            </div>
            <div className={`payment-method-input-div creditcard ${selectedMethod === 'creditcard' ? 'active' : ''}`}>
                <label htmlFor="creditcard-option" className="payment-method-label" onClick={() => selectMethod('creditcard')}>
                    <div className="payment-pay-method-inner-bar">
                        <div className="payment-pay-method-check-area">
                            <label className="radio">
                                <input type="radio" name="payment-method" id="creditcard-option" value="creditcard" />
                                <span className="radio-btn"></span>
                            </label>
                        </div>
                        <div className="payment-method-title-area">
                            <p className="payment-method-title-p">Krediet-/ Debetkaart</p>
                            <img src={creditcard} alt="mastercard" className="payment-method-issuers-img" />
                        </div>
                    </div>
                    <div className="payment-method-content-div">
                        <form className="payment-method-form creditcard" onSubmit={handleCreditCardSubmit}>
                            <div className="payment-method-row">
                                <div className="form-group form-group-card-number">
                                    <label htmlFor="card-number" className="payment-method-input-label">Kaartnummer</label>
                                    <div className="payment-method-input-area">
                                        <div id="card-number"></div>
                                        {cardNumberError && <div id="card-number-error" className='form-field-error'>{cardNumberError}</div>}
                                    </div>
                                </div>
                            </div>

                            <div id="betaling-pay-method-row">
                                <div className="form-group form-group-card-holder">
                                    <label htmlFor="card-holder" className="payment-method-input-label">Naam</label>
                                    <div className="payment-method-input-area">
                                        <div id="card-holder"></div>
                                        {cardHolderError && <div id="card-holder-error" className='form-field-error'>{cardHolderError}</div>}
                                    </div>
                                </div>
                            </div>

                            <div id="betaling-pay-method-row">
                                <div className="form-group form-group-expiry-date">
                                    <label htmlFor="expiry-date" className="payment-method-input-label">Vervaldatum</label>
                                    <div className="payment-method-input-area">
                                        <div id="expiry-date"></div>
                                        {expiryDateError && <div id="expiry-date-error" className='form-field-error'>{expiryDateError}</div>}
                                    </div>
                                </div>
                                <div className="form-group form-group-verification-code">
                                    <label htmlFor="verification-code" className="payment-method-input-label">Verificatiecode</label>
                                    <div className="payment-method-input-area">
                                        <div id="verification-code"></div>
                                        {verificationCodeError && <div id="verification-code-error" className='form-field-error'>{verificationCodeError}</div>}
                                    </div>
                                </div>
                            </div>
                            <div id="creditcard-error" className="form-field-error"></div>
                            <button className="payment-method-submit-butt" type='submit'>
                                <span className="payment-method-submit-butt-txt-span">Betaal € {amountInEuro} ter verificatie</span>
                                <span className="payment-method-submit-butt-icon-span material-symbols-rounded">arrow_forward</span>
                            </button>
                        </form>
                    </div>
                </label>
            </div>

        </div>
    );
};

export default PaymentMethodView;
