import React, { useState } from 'react';
import './AddPlanThingModal.css';
import ViewAddCreatedThings from '../ViewAddCreatedThings/ViewAddCreatedThings';
import { planActions } from '../../../../api/actions/actionsService';
import { getSubsidiary } from '../../../../api/auth/user-info';
import { Action, CreateAction } from '../../../../models/Action';
// import { v4 as uuidv4 } from 'uuid';


interface AddPlanThingModalProps {
    thingtype: { id: number; plural: string, singular: string, color: string, };
    onAddThingClick: () => void;
    onPlanThingClick: (thing: any) => void;
    closeAddPlanThingModal: () => void;
    refreshKey: number;
    selectedDate: Date;
    addActions: (addingActions: Action[]) => void;
}

const AddPlanThingModal: React.FC<AddPlanThingModalProps> = (props) => {

    // const generateUUID = (): string => {
    //     return uuidv4();
    // };

    const [selectedThings, setSelectedThings] = React.useState<any[]>([]);

    const onPlanThingClick = (thing: any) => {
        if (selectedThings.includes(thing)) {
            setSelectedThings(selectedThings.filter(item => item !== thing));
        } else {
            if (selectedThings.length < 2) {
                setSelectedThings([...selectedThings, thing]);
            } else {
            }
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        console.log('Selected date:', props.selectedDate.toISOString().slice(0, 10));
        console.log('Plan things:', selectedThings);
        const subsidiary = getSubsidiary();

        if (selectedThings.length !== 0) {
            var actions: any[] = [];
            selectedThings.forEach((thing) => {
                var action: CreateAction = {
                    subsidiary_id: subsidiary.id,
                    thing_type_id: thing.type_id,
                    thing_id: thing.id,
                    title: thing.title,
                    date: props.selectedDate.toISOString().slice(0, 10),
                    description: thing.description,
                    day_type: undefined,
                    specific_day: undefined,
                    action_link_uuid: undefined,
                    active_on_each: 0,
                    comments: undefined,
                    custom_frequency: undefined,
                    repeat_every: 0,
                    repeat_type: undefined,
                    start_date: props.selectedDate.toISOString().slice(0, 10),
                    end_date: props.selectedDate.toISOString().slice(0, 10),
                    sort: 0,
                    monthly_days: {},
                    weekly_days: {},
                    yearly_months: {},
                    is_planned: true,
                    is_pinned: true,
                    completed_on: undefined,
                    from_template: false
                };
                planActions(action);
                actions.push(action);
            });

            console.log('Actions:', actions);
            
            props.addActions(actions);
        }
        props.closeAddPlanThingModal();
    };

    return (
        <div>
            <form className="addplanthing-form" onSubmit={handleSubmit}>
                <ViewAddCreatedThings thingtype={props.thingtype} onAddThingClick={props.onAddThingClick} refreshKey={props.refreshKey} onPlanThingClick={onPlanThingClick} selectedThings={selectedThings} />
                <div className="addplanthing-input-bar">
                    <button className='addplanthing-submit-control-btn' type="submit">Add Thing</button>
                    <button onClick={props.closeAddPlanThingModal} className='addplanthing-cancel-control-btn' type="button">Annuleren</button>
                </div>
            </form>
        </div >
    );
};

export default AddPlanThingModal;