import React from 'react';
import './AccountSettings.css';
import '../InnerViews.css';
import { Tab, Tabs } from '@nextui-org/react';
import { getUserInfo, updateUserInfo } from '../../../../../api/auth/user-info';
import { updateUser } from '../../../../../api/users/usersService';
import { Helmet } from 'react-helmet-async';

interface AccountSettingsProps {
    setSelectedEditOverlay: (value: string) => void;
    setModalIsOpen: (value: boolean) => void;
    setModalTitle: (value: string) => void;
    selectedMenu: Boolean;
    setSelectedMenu: React.Dispatch<React.SetStateAction<Boolean>>;
}

const AccountSettings: React.FC<AccountSettingsProps> = (props) => {
    // Add your component logic here

    const [editingArea, setEditingArea] = React.useState<string>('');


    const handleEditClick = (type: string) => {
        setEditingArea(type);
    };

    const userInfo = getUserInfo();

    console.log(userInfo);

    const handleActionClick = (action: string) => {
        if (action == 'wachtwoord') {
            props.setModalTitle('Wachtwoord wijzigen');
        } else if (action == 'verwijderen') {
            props.setModalTitle('Weet je zeker dat je je account wilt verwijderen?');
        }
        props.setSelectedEditOverlay(action);
        props.setModalIsOpen(true);

    };

    const [fname, setFname] = React.useState<string>(userInfo.firstname);
    const [lname, setLname] = React.useState<string>(userInfo.lastname);
    const [email, setEmail] = React.useState<string>(userInfo.email);
    const [tel, setTel] = React.useState<string>(userInfo.phone_number);

    const handleSaveClick = () => {
        if (editingArea === 'naam') {
            updateUser(userInfo.id, { firstname: fname, lastname: lname });

            updateUserInfo({ firstname: fname, lastname: lname });

            setEditingArea('');
        } else if (editingArea === 'contactgegevens') {
            updateUser(userInfo.id, { email: userInfo.email, phone_number: userInfo.phone_number });
            setEditingArea('');
        }
    };

    const handleCloseBtnClick = () => {
        props.setSelectedMenu(!props.selectedMenu);
    };

    return (
        <div className='accountsettings-container'>
            <Helmet>
                <title>10-Things | Portaal | Account Instellingen</title>
            </Helmet>
            <div className="settings-top-bar">
                <h2 className="settings-title-h2">Accountgegevens</h2>
                {editingArea !== '' &&
                    <div className="settings-controls-area">
                        <button className="settings-cancel-btn" type='button' onClick={() => handleEditClick('')}>
                            Annuleren
                        </button>
                        <button className="settings-save-btn" type='button' onClick={handleSaveClick}>
                            Opslaan
                        </button>
                    </div>
                }
                <button className="settings-mobile-close-btn" type='button' onClick={handleCloseBtnClick}>
                    <span className="material-symbols-rounded settings-mobile-close-icon-span">close</span>
                </button>
            </div>
            <div className="settings-inner-content">
                <ul className="settings-listing-ul">
                    <li className="settings-item-li">
                        {editingArea !== 'naam' && <div className="settings-static-values">
                            <div className="settings-left-area">
                                <p className="settings-type-txt-p">Naam</p>
                                <p className="settings-value-txt-p">{userInfo.firstname} {userInfo.lastname}</p>
                            </div>

                            <div className="settings-right-area">
                                <button className="settings-edit-btn" type='button' onClick={() => handleEditClick('naam')}>
                                    Wijzigen
                                </button>
                            </div>
                        </div>}
                        {editingArea === 'naam' &&
                            <div className="settings-edit-values">
                                <div className="settings-edit-bar">
                                    <div className="settings-edit-label-area">
                                        <label className='settings-edit-label' htmlFor="fname">Volledige naam</label>
                                    </div>
                                    <div className="settings-edit-input-area">
                                        <input type="text" className="settings-edit-input half-input" id='fname' name="fname" value={fname} onChange={(event) => setFname(event.target.value)} />
                                        <input type="text" className="settings-edit-input half-input" id="lname" name="lname" value={lname} onChange={(event) => setLname(event.target.value)} />
                                    </div>
                                </div>
                            </div>}
                    </li>
                    <li className="settings-item-li">
                        {editingArea !== 'contactgegevens' && <div className="settings-static-values">
                            <div className="settings-left-area">
                                <p className="settings-type-txt-p">Contactgegevens</p>
                                <p className="settings-value-txt-p">E-mail: {userInfo.email}</p>
                                <p className="settings-value-txt-p">Telefoon: {userInfo.phone_number}</p>
                            </div>

                            <div className="settings-right-area">
                                <button className="settings-edit-btn" type='button' onClick={() => handleEditClick('contactgegevens')}>
                                    Wijzigen
                                </button>
                            </div>
                        </div>}
                        {editingArea === 'contactgegevens' && <div className="settings-edit-values">
                            <div className="settings-edit-bar">
                                <div className="settings-edit-label-area">
                                    <label className='settings-edit-label' htmlFor="email">E-mail</label>
                                </div>
                                <div className="settings-edit-input-area">
                                    <input type="text" className="settings-edit-input" id='email' name="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                                </div>
                            </div>

                            <div className="settings-edit-bar">
                                <div className="settings-edit-label-area">
                                    <label className='settings-edit-label' htmlFor="tel">Telefoon</label>
                                </div>
                                <div className="settings-edit-input-area">
                                    <input type="text" className="settings-edit-input" id='tel' name="tel" value={tel} onChange={(event) => setTel(event.target.value)} />
                                </div>
                            </div>
                        </div>}
                    </li>
                    <li className="settings-item-li">
                        <div className="settings-static-values">
                            <div className="settings-left-area">
                                <p className="settings-type-txt-p">Wachtwoord</p>
                                <p className="settings-value-txt-p">Stel een uniek wachtwoord in om je account te beschermen.</p>
                            </div>

                            <div className="settings-right-area">
                                <button className="settings-edit-btn" type='button' onClick={() => handleActionClick('wachtwoord')}>
                                    Wijzig wachtwoord
                                </button>
                            </div>
                        </div>
                    </li>
                    <li className="settings-item-li">
                        <div className="settings-static-values">
                            <div className="settings-left-area">
                                <p className="settings-type-txt-p">Verwijder account</p>
                                <p className="settings-value-txt-p">Hier kan je je account verwijderen. Je account wordt dan permanent verwijderd van 10-Things</p>
                            </div>

                            <div className="settings-right-area">
                                <button className="settings-delete-btn" type='button' onClick={() => handleActionClick('verwijderen')}>
                                    Verwijderen
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            {editingArea !== '' &&
                <div className="mobile-settings-save-area">
                    <button className="settings-cancel-btn" type='button' onClick={() => handleEditClick('')}>
                        Annuleren
                    </button>
                    <button className="settings-save-btn" type='button' onClick={handleSaveClick}>
                        Opslaan
                    </button>
                </div>
            }

        </div>
    );
};

export default AccountSettings;