import React, { useEffect, useState } from 'react';
import './CalendarMonthView.css';
import SideCalendar from './SideCalendarView/SideCalendarView';
import { fetchEvents } from '../../../../api/events/eventsService';
import useIsMobile from '../../../../utils/userIsMobile';

interface CalendarMonthViewProps {
    // events: { title: string, date: string, eventType: string; }[];
    currentMonth: Date;
}

const CalendarMonthView: React.FC<CalendarMonthViewProps> = ({ currentMonth }) => {
    const [events, setEvents] = useState<any[]>([]);

    useEffect(() => {
        const loadEvents = async () => {
            try {

                const events = await fetchEvents();
                console.log('Events:', events);
                setEvents(events);
            } catch (error) {
                console.error('Failed to fetch events:', error);
            } finally {
                console.log('Events loaded');
            }
        }

        loadEvents();
    }, []);

    var month = new Date(currentMonth);

    return (
        <div className='calendar-view-container'>
            <div className="calendar-mobile-view">

            </div>
            <div className="calendar-full-view-area">
                <div key={month.toISOString()} className='calendar-month-area-div'>
                    <div className='calendar-day-labels'>
                        <span>Maandag</span>
                        <span>Dinsdag</span>
                        <span>Woensdag</span>
                        <span>Donderdag</span>
                        <span>Vrijdag</span>
                        <span>Zaterdag</span>
                        <span>Zondag</span>
                    </div>
                    <div className='calendar-month-inner-content'>
                        <div className="calendarview-grid">
                            {(() => {
                                const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
                                const lastDayOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);
                                const firstDayOfWeek = firstDayOfMonth.getDay(); // 0 (Sunday) to 6 (Saturday)
                                const lastDayOfWeek = lastDayOfMonth.getDay(); // 0 (Sunday) to 6 (Saturday)

                                // Calculate the number of placeholders before the first day of the month
                                const placeholdersBefore = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

                                // Calculate the number of placeholders after the last day of the month
                                const placeholdersAfter = lastDayOfWeek === 0 ? 0 : 7 - lastDayOfWeek;

                                // Generate the calendar cells
                                const calendarCells = [];

                                // Add placeholders before the first day of the month
                                for (let i = 0; i < placeholdersBefore; i++) {
                                    calendarCells.push(<div key={`before-${i}`} className="date-placeholder"></div>);
                                }

                                // Add the actual days of the month
                                for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
                                    const date = new Date(month.getFullYear(), month.getMonth(), i);
                                    const dateString = date.toISOString();

                                    const eventsOnThisDate = events.filter(event => {
                                        const eventDate = new Date(event.date);
                                        return eventDate.getFullYear() === date.getFullYear() &&
                                            eventDate.getMonth() === date.getMonth() &&
                                            eventDate.getDate() === date.getDate();
                                    });

                                    calendarCells.push(
                                        <div key={dateString} className="calendar-date-item">
                                            <h1 className="calendar-day-txt-h1">{date.getDate()}</h1>
                                            <ul className="calendar-content-listing-ul">
                                                {eventsOnThisDate.map((event, eventIndex) => {
                                                    let eventtypeclass;

                                                    if (event.event_type === 1) {
                                                        eventtypeclass = 'holidays';
                                                    } else if (event.event_type === 2) {
                                                        eventtypeclass = 'wouter_events';
                                                    } else if (event.event_type === 3) {
                                                        eventtypeclass = 'vacations';
                                                    } else if (event.event_type === 4) {
                                                        eventtypeclass = 'sports';
                                                    }

                                                    return (
                                                        <li key={event.id} className={`calendar-content-listing-item ${eventtypeclass}`}>
                                                            <button className="calendar-content-listing-btn" type='button'>
                                                                <div className="calendar-content-inner">
                                                                    <p className="calendar-content-listing-span">{event.name}</p>
                                                                </div>
                                                            </button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    );
                                }

                                // Add placeholders after the last day of the month
                                for (let i = 0; i < placeholdersAfter; i++) {
                                    calendarCells.push(<div key={`after-${i}`} className="date-placeholder"></div>);
                                }

                                return calendarCells;
                            })()}

                        </div>
                    </div>
                </div>
            </div>
            <div className="calendar-side-view-area">
                <SideCalendar visibleMonths={1} minStartDate={new Date()} />
            </div>
        </div>
    );
};

export default CalendarMonthView;