import React, { useEffect, useRef } from 'react';
import './NoteOverlay.css';
import TextareaAutosize from 'react-textarea-autosize';
import { createNote } from '../../../../api/notes/notesService';
import { getSubsidiary } from '../../../../api/auth/user-info';
import { Note } from '../../../../models/Note';
import { NoteTypes } from '../../../../enums/NoteTypes';


interface NoteOverlayProps {
    note?: Note;
    isOpen: boolean;
    onClose: () => void;
    updateNote?: (note: Note) => void;
    onAddNote?: (note: any) => void;
    onDeleteNote?: (note_id: number) => void;
    readOnly?: boolean;
}

const NoteOverlay: React.FC<NoteOverlayProps> = (props) => {

    if (!props.isOpen) {
        return null;
    }

    console.log('Note:', props.note);

    var noteTypeClass;
    var noteTypeTitle;
    switch (props.note?.note_type) {
        case NoteTypes.GREEN:
            noteTypeClass = 'note-type-1';
            noteTypeTitle = 'Wat ging er goed?';
            break;
        case NoteTypes.ORANGE:
            noteTypeClass = 'note-type-2';
            noteTypeTitle = 'Wat kan beter?';
            break;
        case NoteTypes.YELLOW:
            noteTypeClass = 'note-type-3';
            noteTypeTitle = 'Welke bijzonderheden?';
            break;
        default:
            noteTypeClass = 'note-type-default'; // Default class
            break;
    }

    var creatingNote = false

    if (props.note === null) {
        creatingNote = true;
    }

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const onTextareaChange = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        props.note!.note_txt = event.target.value;
        console.log('Note:', props.note);
        // if (event.target.value === '') {
        //     props.onDeleteNote!(props.note.id);
        //     return;
        // }
        props.updateNote!(props.note!);
    };

    const onTextareaBlur = async (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const note_txt = event.target.value;
        if (note_txt === '') {
            return;
        }
        const subsidiary_id = getSubsidiary().id;
        const note = {
            subsidiary_id: subsidiary_id,
            note_txt: note_txt,
        };

        var response = await createNote(note);
        props.onAddNote!(response);
    };

    console.log(props.readOnly);

    return (
        <div className="note-overlay" onClick={props.onClose}>
            <div className="note-wrapper">
                <div className={`note-content ${noteTypeClass}`} onClick={stopPropagation}>
                    <div className="note-top-bar">
                        <p className="note-title-txt-p">{noteTypeTitle}</p>
                        <button className="note-close-btn" onClick={props.onClose}>
                            <span className="note-close-icon material-symbols-outlined">close</span>
                        </button>
                    </div>
                    {(!props.readOnly && creatingNote) && (
                        <textarea className='note-textarea' onBlur={onTextareaBlur} />)}

                    {(!props.readOnly && !creatingNote) && (
                        <textarea className='note-textarea' defaultValue={props.note!.note_txt} onBlur={onTextareaChange} />)}
                    {props.readOnly && (
                        <textarea className='note-textarea' defaultValue={props.note!.note_txt} readOnly />)}
                </div>
            </div>
        </div>
    );
};

export default NoteOverlay;