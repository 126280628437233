import { Routes, Route } from 'react-router-dom';
import NotFound from '../../NotFoundView/NotFoundView'; // import your NotFound component
import React, { useEffect, useState } from 'react';
import './PortaalView.css';
import LoginView from '../LoginView/LoginView';
import DashboardView from '../DashboardView/DashboardView';
import PlanningView from '../PlanningView/PlanningView';
import ReportView from '../ReportView/ReportView';
import ThingsView from '../ThingsView/ThingsView';
import LevyView from '../LevyView/LevyView';
import InvoicesView from '../InvoicesView/InvoicesView';
import EmployeeView from '../EmployeeView/EmployeeView';
import ReportsView from '../ReportsView/ReportsView';
import TimeTableView from '../TimeTableView/TimeTableView';
import TasksListView from '../TasksListView/TasksListView';
import MenuUploadView from '../MenuUploadView/MenuUploadView';
import SettingsView from '../SettingsView/SettingsView';
import { fetchActiveShift } from '../../../api/shift/shiftService';
import { Shift } from '../../../models/Shift';
import { Action } from '../../../models/Action';
import { Vip } from '../../../models/Vip';
import AddSubsidiaryView from '../AddSubsidiaryView/AddSubsidiaryView';
import { getSubsidiary } from '../../../api/auth/user-info';
import { fetchActiveSubscriptionByParentCompany } from '../../../api/subscriptions/subscriptionService';
import { Subscription } from '../../../models/Subscription';
import SelectSubscriptionView from '../SelectSubscriptionView/SelectSubscriptionView';
import { Spinner } from '@nextui-org/react';

const PortalView: React.FC = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [subsidiary, setSubsidiary] = useState(getSubsidiary());
  const [activeShift, setActiveShift] = useState<Shift | null>(null);
  const [actions, setActions] = useState<Action[]>([]);
  const [vips, setVips] = useState<Vip[]>([]);
  const [notes, setNotes] = useState<any>([]);
  const [refreshShiftKey, setRefreshShiftKey] = useState(0);
  const [refreshSubsidiaryKey, setRefreshSubsidiaryKey] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState<Subscription | null>();
  const [subscriptionType, setSubscriptionType] = useState<string>('10TH1M');

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    const loadData = async () => {
      if (subsidiary !== null) {
        const activeShift = await fetchActiveShift();
        if (activeShift !== null) {
          setActiveShift(activeShift);
          setActions(activeShift.actions);
          setNotes(activeShift.notes);
          setVips(activeShift.vips);
        }

        try {
          const response = await fetchActiveSubscriptionByParentCompany();
          setActiveSubscription(response);
          if (response) {
            setSubscriptionType(response.product_id);
          }
        } catch (err) {
          console.error('Failed to fetch subscription:', err);
        }
      }
      setLoading(false); // Set loading to false after data is loaded
    };

    loadData();
  }, [refreshShiftKey, refreshSubsidiaryKey]);

  useEffect(() => {
    setSubsidiary(getSubsidiary());
  }, [refreshSubsidiaryKey]);

  const DashboardViewProps = {
    toggleNav: toggleNav,
    isNavOpen: isNavOpen,
    activeShift: activeShift,
    setActiveShift: setActiveShift,
    actions,
    setActions,
    vips,
    setVips,
    notes,
    setNotes,
    refreshShiftKey,
    setRefreshShiftKey
  };

  if (loading) {
    return <div className="loading-wrapper">
      <Spinner color="primary" size='lg'></Spinner>
    </div>; // Show loading spinner while loading
  }

  if (!activeSubscription && subsidiary !== null) {
    return <SelectSubscriptionView />;
  }

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {subsidiary !== null && activeSubscription && (
        <>
          <Route path="dashboard" element={<DashboardView {...DashboardViewProps} />} />
          <Route path="planning" element={<PlanningView isNavOpen={isNavOpen} toggleNav={toggleNav} />} />
          <Route path='rapport' element={<ReportView isNavOpen={isNavOpen} toggleNav={toggleNav} />} />
          <Route path='things' element={<ThingsView isNavOpen={isNavOpen} toggleNav={toggleNav} />} />
          <Route path='medewerkers' element={<EmployeeView isNavOpen={isNavOpen} toggleNav={toggleNav} />} />
          <Route path='facturen' element={<InvoicesView isNavOpen={isNavOpen} toggleNav={toggleNav} />} />
          <Route path='rapporten' element={<ReportsView isNavOpen={isNavOpen} toggleNav={toggleNav} />} />
          <Route path='rooster' element={<TimeTableView isNavOpen={isNavOpen} toggleNav={toggleNav} />} />
          <Route path='takenlijsten' element={<TasksListView isNavOpen={isNavOpen} toggleNav={toggleNav} />} />
          <Route path='menu-upload' element={<MenuUploadView isNavOpen={isNavOpen} toggleNav={toggleNav} />} />
          <Route path='instellingen' element={<SettingsView isNavOpen={isNavOpen} toggleNav={toggleNav} activeSubscription={activeSubscription} setActiveSubscription={setActiveSubscription} />} />
        </>
      )}
      {subsidiary === null && <Route path='voeg-vestiging-toe' element={<AddSubsidiaryView setRefreshSubsidiaryKey={setRefreshSubsidiaryKey} />} />}
    </Routes>
  );
}

export default PortalView;