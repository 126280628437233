import React, { useEffect } from 'react';
import './SubscriptionSettings.css';
import { Skeleton, Tab, Tabs } from '@nextui-org/react';
import { fetchProductByIdentifier } from '../../../../../api/products/productsService';
import { useNavigate } from 'react-router-dom';
import { addTransaction } from '../../../../../api/transactions/transactionsService';
import { cancelSubscription, fetchActiveSubscriptionByParentCompany } from '../../../../../api/subscriptions/subscriptionService';
import { fetchCustomerByParentCompany } from '../../../../../api/mollie_customer/mollie_customerServices';
import { getUserInfo } from '../../../../../api/auth/user-info';
import { Subscription } from '../../../../../models/Subscription';
import { Helmet } from 'react-helmet-async';

interface SubscriptionSettingsProps {
    // Add any props you need for the component here
    selectedMenu: Boolean;
    setSelectedMenu: React.Dispatch<React.SetStateAction<Boolean>>;
    openCancelSubscriptionPopUp: () => void;
    activeSubscription: Subscription;
}

const SubscriptionSettings: React.FC<SubscriptionSettingsProps> = ({ selectedMenu, setSelectedMenu, openCancelSubscriptionPopUp, activeSubscription }) => {
    // Add your component logic here

    const subscriptions = ['10TH1M', '10TH1Y'];

    const [subscriptionType, setSubscriptionType] = React.useState<string>('10TH1M');

    const changeSubscriptionType = (type: string) => {
        console.log('Type:', type);
        setSubscriptionType(type);
    }

    const [product, setProduct] = React.useState<any>();

    useEffect(() => {
        const loadProductByIdentifier = async () => {
            try {
                const response = await fetchProductByIdentifier(subscriptionType as string);
                console.log('Product:', response);
                setProduct(response);
            } catch (err) {
                console.error('Failed to fetch product:', err);
            } finally {
                console.log('Done fetching product');
            }
        }

        loadProductByIdentifier();

    }, [subscriptionType]);

    let totalInEuro = '';

    if (product) {
        totalInEuro = product.price.toLocaleString('de-DE', {
            minimumFractionDigits: 2, // Adjust as needed for decimal places
            maximumFractionDigits: 2, // Adjust as needed for decimal places
        });
    } else {
        // Handle the case where product is undefined
        // For example, you might want to set totalInEuro to a default value or log an error
        totalInEuro = ''; // Example default value
    }
    const navigate = useNavigate();

    const handleSelectSubscription = async () => {

        // Redirect the user to the desired path
        navigate({ pathname: '/betalen', search: `?p=${subscriptionType}` });
    };

    const handleCloseBtnClick = () => {
        setSelectedMenu(!selectedMenu);
    };

    let subscriptionButton;

    if (activeSubscription && subscriptionType === activeSubscription.product_id) {
        subscriptionButton = (<button className="settings-cancel-subscription-btn" onClick={openCancelSubscriptionPopUp} type='button'>
            Annuleer abonnement
        </button>);
    } else if (activeSubscription) {
        subscriptionButton = (<button className="settings-edit-subscription-btn" onClick={handleSelectSubscription} type='button'>
            Wijzig abonnement
        </button>);
    } else {
        subscriptionButton = (<button className="settings-start-subscription-btn" onClick={handleSelectSubscription} type='button'>
            Start abonnement
        </button>);
    }

    return (
        <div className='subscriptions-container'>
            <Helmet>
                <title>10-Things | Portaal | Abonnement Instellingen</title>
            </Helmet>
            <div className="settings-top-bar">
                <h2 className="settings-title-h2">Abonnement</h2>
                <button className="settings-mobile-close-btn" type='button' onClick={handleCloseBtnClick}>
                    <span className="material-symbols-rounded settings-mobile-close-icon-span">close</span>
                </button>
            </div>
            <div className="settings-subscription-content-box">
                <div className="settings-inner-content">
                    <h1 className="settings-subscription-title-h1">{activeSubscription ? 'Uw actieve abonnement.' : 'Krijg volledige toegang.'}</h1>

                    <p className="settings-subscription-txt-p">{activeSubscription ? '' : 'Selecteer het juiste plan beginnend vanaf 40 euro per maand.'}</p>
                    {!activeSubscription ? <Tabs color='primary' aria-label="Tabs colors" radius="full" className='subscription-type-tabs' onSelectionChange={(key) => changeSubscriptionType(key.toString())} selectedKey={subscriptionType}>
                        <Tab key="10TH1M" title="Maandelijks" />
                        <Tab key="10TH1Y" title="Jaarlijks" />
                    </Tabs> : null}

                    <div className="settings-subscriptions-area">
                        <div className="settings-subscription-card">
                            <h3 className="settings-subscription-card-title">Basis</h3>
                            <p className="settings-subscription-card-desc-txt-p">Voor een enkele vestiging.</p>
                            <hr className='settings-subscription-divider' />
                            <h1 className="settings-subscription-pricing-h1">€ {product ? totalInEuro : ''} <span className="settings-subscription-small-span">/ per maand</span></h1>
                            <ul className="settings-subscription-listing-ul">
                                <li className="select-subscription-percs-item-li">
                                    <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                    <p className="select-subscription-percs-txt-p">Toegang tot alle functionaliteiten</p>
                                </li>
                                <li className="select-subscription-percs-item-li">
                                    <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                    <p className="select-subscription-percs-txt-p">7 dagen proefperiode</p>
                                </li>
                                <li className="select-subscription-percs-item-li">
                                    <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                    <p className="select-subscription-percs-txt-p">1-4 shifts</p>
                                </li>
                                <li className="select-subscription-percs-item-li">
                                    <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                    <p className="select-subscription-percs-txt-p">150+ events</p>
                                </li>
                                <li className="select-subscription-percs-item-li">
                                    <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                    <p className="select-subscription-percs-txt-p">Dagelijks evaluatierapport</p>
                                </li>
                                <li className="select-subscription-percs-item-li">
                                    <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                    <p className="select-subscription-percs-txt-p">Laat je inspireren door 500+ focuspunten van Wouter Verkerk</p>
                                </li>
                            </ul>
                            {subscriptionButton}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionSettings;