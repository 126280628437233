import React from 'react';
import './NoteItemDeBriefing.css';
import { Note } from '../../../../../models/Note';

interface NoteItemDeBriefingProps {
    note_txt: string;
    setNoteTxt: (txt: string) => void;
    note_type: number;
}

const NoteItemDeBriefing: React.FC<NoteItemDeBriefingProps> = ({ note_txt, setNoteTxt, note_type }) => {
    let note_type_class;
    if (note_type === 1) {
        note_type_class = 'note-type-1';
    } else if (note_type === 2) {
        note_type_class = 'note-type-2';
    } else if (note_type === 3) {
        note_type_class = 'note-type-3';
    }
    return (
        <div className={`note-item-debriefing ${note_type_class}`}>
            <textarea id="note-item-textarea" onChange={(e) => setNoteTxt(e.target.value)} value={note_txt}>{note_txt}</textarea>
        </div>
    );
};

export default NoteItemDeBriefing;