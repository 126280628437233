import React from 'react';
import PageLayout from '../../components/Site/PageLayout/PageLayout';
import './ContactView.css';
import { Input, Textarea } from '@nextui-org/react';
import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../utils/usePageTracking';

interface ContactViewProps {
  // Define any props you need for the ContactView component
}

const ContactView: React.FC<ContactViewProps> = () => {
  usePageTracking();
  return (
    <PageLayout>
      <Helmet>
        <title>10-Things | Contact</title>
      </Helmet>
      <section className="contact-section">
        <h1 className="contact-title-h1">Neem contact op</h1>
        <p className="contact-txt-p">Heb je vragen of wil je meer informatie over 10-Things? Neem dan contact met ons op via het onderstaande formulier.</p>
        <form className="contact-form">
          <Input className='contact-input' type="text" label="Naam" variant="bordered" />
          <Input className='contact-input' type="email" label="E-mail" variant="bordered" />
          <Input className='contact-input' type="phone" label="Telefoon" variant="bordered" />
          <Textarea
            className='contact-input'
            label="Bericht"
            placeholder="Schrijf hier je vragen of opmerkingen"
            variant="bordered"
            minRows={10}
          />
          <p className="contact-accept-privacy">Door dit formulier te versturen ga je akkoord met onze Algemene Voorwaarden.</p>
          <button type="submit" className="contact-submit-btn">Verstuur</button>
        </form>
      </section>
    </PageLayout>
  );
};

export default ContactView;