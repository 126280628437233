// export const userInfo = window.localStorage.getItem('user_info') ? JSON.parse(window.localStorage.getItem('user_info')!) : '';
// export const tokenInfo = window.localStorage.getItem('tokens') ? JSON.parse(window.localStorage.getItem('tokens')!) : '';
// export const subsidiaryID = window.localStorage.getItem('subsidiary_id') ? window.localStorage.getItem('subsidiary_id') : '';

import { Subsidiary } from "../../models/Subsidiary";

export function getUserInfo() {
    return window.localStorage.getItem('user_info') ? JSON.parse(window.localStorage.getItem('user_info')!) : '';
}

export function updateUserInfo(updates: Partial<Record<string, any>>): void {
    // Retrieve the current user_info
    const userInfo = getUserInfo();

    // Check if userInfo is an object and not an empty string
    if (typeof userInfo === 'object' && userInfo !== null) {
        // Update the userInfo object with the new values
        const updatedUserInfo = { ...userInfo, ...updates };

        // Save the updated userInfo back to localStorage
        window.localStorage.setItem('user_info', JSON.stringify(updatedUserInfo));
    } else {
        console.error('User info is not an object or is empty');
    }
}

export function getTokenInfo() {
    return window.localStorage.getItem('tokens') ? JSON.parse(window.localStorage.getItem('tokens')!) : '';
}

export function getSubsidiary() {
    const subsidiary = window.localStorage.getItem('subsidiary');
    
    if (subsidiary === null) {
        return null;
    }

    try {
        return JSON.parse(subsidiary);
    } catch (error) {
        return null;
    }
}

export function updateSubsidiaryInfo(updates: Partial<Subsidiary>): void {
    // Retrieve the current subsidiary info
    const subsidiaryInfo = getSubsidiary();

    // Check if subsidiaryInfo is an object and not an empty string
    if (typeof subsidiaryInfo === 'object' && subsidiaryInfo !== null) {
        // Update the subsidiaryInfo object with the new values
        const updatedSubsidiaryInfo = { ...subsidiaryInfo, ...updates };

        // Save the updated subsidiaryInfo back to localStorage
        window.localStorage.setItem('subsidiary', JSON.stringify(updatedSubsidiaryInfo));
    } else {
        console.error('Subsidiary info is not an object or is empty');
    }
}