import { useState, useEffect, useCallback } from 'react';

let molliePromise: Promise<any> | null = null;

const loadMollieScript = (profileId: string, retries: number = 3, delay: number = 1000): Promise<any> => {
    return new Promise((resolve, reject) => {
        const attemptLoad = (attempt: number) => {
            const script = document.createElement('script');
            script.src = 'https://js.mollie.com/v1/mollie.js';
            script.addEventListener('load', () => {
                try {
                    const mollieInstance = new (window as any).Mollie(profileId, { locale: 'nl_NL', testmode: true });
                    resolve(mollieInstance);
                } catch (error) {
                    if (attempt < retries) {
                        setTimeout(() => attemptLoad(attempt + 1), delay);
                    } else {
                        reject(error);
                    }
                }
            });
            script.addEventListener('error', () => {
                if (attempt < retries) {
                    setTimeout(() => attemptLoad(attempt + 1), delay);
                } else {
                    reject(new Error('Failed to load Mollie script'));
                }
            });
            document.body.appendChild(script);
        };

        attemptLoad(0);
    });
};

const useMollie = (profileId: string, effect: (mollie: any) => void, deps: any[] = []) => {
    const [mollie, setMollie] = useState<any>(null);
    const mollieCb = useCallback(effect, deps);

    useEffect(() => {
        if (!molliePromise) {
            molliePromise = loadMollieScript(profileId);
        }

        molliePromise.then((mollieInstance) => setMollie(mollieInstance)).catch((error) => {
            console.error('Error loading Mollie:', error);
        });
    }, [profileId]);

    useEffect(() => {
        if (mollie) {
            mollieCb(mollie);
        }
    }, [mollie, mollieCb]);

    return mollie;
};

export default useMollie;