// HistoryContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const HistoryContext = createContext<string[]>([]);

interface HistoryProviderProps {
    children: React.ReactNode;
}

export const HistoryProvider: React.FC<HistoryProviderProps> = ({ children }) => {
    const [history, setHistory] = useState<string[]>([]);
    const location = useLocation();

    useEffect(() => {
        setHistory((prevHistory) => [...prevHistory, location.pathname]);
    }, [location]);

    return (
        <HistoryContext.Provider value={history}>
            {children}
        </HistoryContext.Provider>
    );
};

export const useHistory = () => useContext(HistoryContext);