import React, { useEffect, useState } from 'react';
import './EventWidgetSmall.css';
import { fetchEvents } from '../../../../../api/events/eventsService';

const EventWidgetSmall: React.FC = () => {

    const currentDate = new Date();
    const currentDateInAmsterdam = new Date(currentDate.toISOString().split('T')[0]);
    const [events, setEvents] = useState<any[]>([]);

    useEffect(() => {
        const loadEvents = async () => {
            try {
                const events = await fetchEvents();
                // console.log('Events:', events);
                setEvents(events);
            } catch (error) {
                console.error('Failed to fetch events:', error);
            } finally {
                console.log('Events loaded');
            }
        }

        loadEvents();
    }, []);

    // console.log(events);

    const upcomingEvents = events.filter((event) => {
        const eventDate = new Date(event.date);
        // Print event date in console
        // console.log('Event date:', eventDate);
        return eventDate >= currentDateInAmsterdam;

    })
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .slice(0, 4);

    // console.log('TESTJE', upcomingEvents);


    return (
        <div className='event-widget-small'>
            <p className="event-widget-small-day-txt-p">Aankomende events</p>
            <div className="event-small-scrollable-events">
                <ul>
                    {upcomingEvents.map((event) => {
                        // test per eventje 
                        //{upcomingEvents.filter((value) => value.event_type === 4).map((event) => {
                        // console.log('Event type:', event.event_type); // Log event type

                        let eventtypeclass;

                        if (event.event_type === 1) {
                            eventtypeclass = 'holidays'
                        }
                        else if (event.event_type === 2) {
                            eventtypeclass = 'wouter_events'
                        }
                        else if (event.event_type === 3) {
                            eventtypeclass = 'vacations'
                        }
                        else if (event.event_type === 4) {
                            eventtypeclass = 'sports'
                        }

                        return (
                            <li key={event.id} className={`event-small-item ${eventtypeclass}`}>
                                <div className="event-small-content-inner">
                                    <div className="event-small-date-info-area">
                                        <p className="event-small-startdate-info-txt-p"><strong>{new Date(event.date).toLocaleDateString("nl-NL", { day: "numeric" })} {new Date(event.date).toLocaleDateString("nl-NL", { month: "long" })}</strong></p>
                                        {event.end_date && <p className="event-small-enddate-info-txt-p">t/m {new Date(event.end_date).toLocaleDateString("nl-NL", { day: "numeric" })} {new Date(event.end_date).toLocaleDateString("nl-NL", { month: "long" })}</p>}
                                    </div>
                                    <div className="event-small-info-area">
                                        {event.name}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};




//     return (
//         <div className="quick-item">
//             <p className="event-widget-small-day-txt-p">Aankomende events</p>
//             <ul id="event-widget-small-content-listing">
//                 <li className="event-widget-small-item">
//                     <p className="event-widget-small-time-txt-p">19-6</p>
//                     <p className="event-widget-small-event-txt-p">EK: Duitsland - Hongarije (18:00)</p>
//                 </li>
//                 <li className="event-widget-small-item">
//                     <p className="event-widget-small-time-txt-p">16-7</p>
//                     <p className="event-widget-small-event-txt-p">Start Nijmeegse vierdaagse</p>
//                 </li>
//             </ul>
//         </div>
//     );
// };

export default EventWidgetSmall;
