import React from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';

interface TasksListViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
}

const TasksListView: React.FC<TasksListViewProps> = ( props ) => {
    return (
        <div>
            <PortaalPageLayout activePage='takenlijst' isNavOpen={props.isNavOpen} toggleNav={props.toggleNav} >
                Test
            </PortaalPageLayout>
        </div>
    );
};

export default TasksListView;