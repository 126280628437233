import axios from 'axios';
import { refreshAccessToken } from './user.auth';
import { apiURL } from '../../devEnv';

let headers: { [key: string]: string } = {};
const tokenInfo = JSON.parse(localStorage.getItem('tokens')!);

if (tokenInfo)
    headers['Authorization'] = `Bearer ${tokenInfo.access_token}`;

const api = axios.create({
    baseURL: apiURL,
    headers: headers
});

let isRefreshing = false;
let refreshQueue: any[] = [];

api.interceptors.request.use(config => {
    const tokenInfo = JSON.parse(localStorage.getItem('tokens')!)
    if (tokenInfo) {
        config.headers['Authorization'] = `Bearer ${tokenInfo.access_token}`;
    } else {
        delete config.headers['Authorization'];
    }
    return config;
});

api.interceptors.response.use(
    response => response, // Simply return the response if everything is fine
    async error => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                // If refresh is already in progress, queue the request and wait
                return new Promise((resolve, reject) => {
                    refreshQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers.Authorization = `Bearer ${token}`;
                        return axios(originalRequest); // Retry the original request with new token
                    })
                    .catch(err => {
                        originalRequest._retry = true; // Mark the request as retried
                        return axios(originalRequest); // Retry the original request
                    });
            }

            originalRequest._retry = true; // Mark the request as retried
            isRefreshing = true;

            try {
                const newAccessToken = await refreshAccessToken();
                if (!newAccessToken) {
                    throw new Error('Failed to refresh access token');
                }
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                api.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
                refreshQueue.forEach(callback => callback.resolve(newAccessToken));
                refreshQueue = [];
                return axios(originalRequest); // Retry the original request with new token
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                refreshQueue.forEach(callback => callback.reject(refreshError));
                refreshQueue = [];
                // Redirect to login page or handle token refresh failure
                window.location.href = '/login'; // Adjust the URL as needed
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }
        return Promise.reject(error);
    }
);

export default api;