import { UploadMenuFile } from "../../models/UploadMenu";
import backend from "../backend";

export const uploadMenu = async (uploadmenu: UploadMenuFile) => {
    const formData = new FormData();
    formData.append('subsidiary_id', uploadmenu.subsidiary_id.toString());
    formData.append('user_id', uploadmenu.user_id.toString());
    formData.append('menu_id', uploadmenu.menu_id.toString());
    formData.append('file', uploadmenu.file);

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        }
    };
    try {
        const url = `/upload-menu/`;
        const response = await backend.post(url, formData, config);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error('Failed to upload menu:', error);
        throw error;
    }
}