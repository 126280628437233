import React, { useEffect } from 'react';
import './SelectSubscriptionView.css';
import { checkLocalStorageItems, logoutUser } from '../../../api/auth/user.auth';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Tab, Tabs } from '@nextui-org/react';
import { getUserInfo } from '../../../api/auth/user-info';
import { fetchCustomerByParentCompany } from '../../../api/mollie_customer/mollie_customerServices';
import { fetchProducts } from '../../../api/products/productsService';
import { Product } from '../../../models/Product';
import { SubscriptionType } from '../../../enums/SubscriptionType';
import usePageTracking from '../../../utils/usePageTracking';

interface SelectSubscriptionViewProps {
    // Add any props you need here
}

const formatPrice = (price: number) => {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);
};

const SelectSubscriptionView: React.FC<SelectSubscriptionViewProps> = () => {
    // Add your component logic here\
    usePageTracking();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logoutUser();
        checkLocalStorageItems().then((result) => {
            console.log(result);
            if (!result) {
                navigate('/login');
            }
        }
        );
    };

    const [mollieCustomer, setMollieCustomer] = React.useState<any>();

    useEffect(() => {
        const loadMollieCustomer = async () => {
            try {
                const user = getUserInfo();
                const response = await fetchCustomerByParentCompany(user.parent_company_id);
                console.log('Mollie Customer:', response);
                setMollieCustomer(response);
            } catch (err) {
            } finally {
                console.log('Done fetching mollie customer');
            }
        }
        loadMollieCustomer();
    }, []);

    const [products, setProducts] = React.useState<Product[]>([]);
    const [subscriptionType, setSubscriptionType] = React.useState<string>('10TH1M');
    const [selectedProduct, setSelectedProduct] = React.useState<Product>();

    useEffect(() => {
        const loadProducts = async () => {
            try {
                const response = await fetchProducts();
                console.log('Products:', response);
                setProducts(response);
            } catch (err) {
                console.error('Failed to fetch products:', err);
            } finally {
                console.log('Done fetching products');
                changeSubscriptionType(subscriptionType);
            }
        };
        loadProducts();
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            changeSubscriptionType(subscriptionType);
        }
    }, [products, subscriptionType]);


    const changeSubscriptionType = (type: string) => {
        console.log('Type:', type);
        setSubscriptionType(type);
        const product = products.find((product) => product.product_identifier === type);
        console.log('Selected Product:', product);
        setSelectedProduct(product);
    }

    const handleSelectSubscription = async () => {

        // Redirect the user to the desired path
        navigate({ pathname: '/betalen', search: `?p=${subscriptionType}` });
    };

    return (
        <div className='select-subscription-view'>
            <Helmet>
                <title>10-Things | Kies je abonnement</title>
            </Helmet>
            <nav className="select-subscription-nav">
                <ul className="select-subscription-listing-ul">
                    <li className="select-subscription-left-li">

                    </li>
                    <li className="select-subscription-center-li">

                    </li>
                    <li className="select-subscription-right-li">
                        <button className="select-subscription-right-close-btn" onClick={handleLogout}>
                            <span className="select-subscription-right-close-icon-span material-symbols-rounded">close</span>
                        </button>
                    </li>
                </ul>
            </nav>
            <div className="select-subscription-content-area">
                <div className="select-subscription-content-inner-area">
                    <h1 className="select-subscription-title">Kies een abonnement</h1>
                    <p className="select-subscription-txt-p">Begin vandaag nog je proefperiode van 7 dagen en annuleer op ieder gewenst moment.</p>
                    <Tabs color='primary' aria-label="Tabs colors" radius="full" className='subscription-type-tabs' onSelectionChange={(key) => changeSubscriptionType(key.toString())} selectedKey={subscriptionType}>
                        <Tab key="10TH1M" title="Maandelijks" />
                        <Tab key="10TH1Y" title="Jaarlijks" />
                    </Tabs>
                    <div className="select-subscription-cards-area">
                        {selectedProduct &&
                            <div className="select-subscription-card">
                                <div className="select-subscription-top-area">
                                    <h2 className="select-subscription-title-h2">Standaard</h2>
                                    <p className="select-subscription-inner-txt-p">Haal alles uit 10-Things met het standaard abonnement.</p>
                                </div>
                                <hr className="select-subscription-divider" />
                                <div className="select-subscription-bottom-area">
                                    <div className="select-subscription-price-area">
                                        <p className="select-subscription-price-p"><span className="select-subscription-price-span">{formatPrice(selectedProduct.price!)}</span> {selectedProduct.recurring_type === SubscriptionType.MONTHLY ? 'per maand' : 'per jaar'}</p>
                                    </div>
                                    <div className="select-subscription-product-percs">
                                        <ul className="select-subscription-percs-listing-ul">
                                            <li className="select-subscription-percs-item-li">
                                                <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                                <p className="select-subscription-percs-txt-p">Toegang tot alle functionaliteiten</p>
                                            </li>
                                            <li className="select-subscription-percs-item-li">
                                                <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                                <p className="select-subscription-percs-txt-p">7 dagen proefperiode</p>
                                            </li>
                                            <li className="select-subscription-percs-item-li">
                                                <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                                <p className="select-subscription-percs-txt-p">1-4 shifts</p>
                                            </li>
                                            <li className="select-subscription-percs-item-li">
                                                <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                                <p className="select-subscription-percs-txt-p">150+ events</p>
                                            </li>
                                            <li className="select-subscription-percs-item-li">
                                                <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                                <p className="select-subscription-percs-txt-p">Dagelijks evaluatierapport</p>
                                            </li>
                                            <li className="select-subscription-percs-item-li">
                                                <span className="select-subscription-percs-icon-span material-symbols-outlined">check</span>
                                                <p className="select-subscription-percs-txt-p">Laat je inspireren door 500+ focuspunten van Wouter Verkerk</p>
                                            </li>
                                        </ul>
                                    </div>
                                        <button className="select-subscription-cta-btn" type="button" onClick={handleSelectSubscription}>Start proefabonnement</button>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectSubscriptionView;