import React, { useState } from 'react';
import './AddEventModal.css';
import { DatePicker, DateValue, Input, Switch, Textarea } from '@nextui-org/react';
import { CreateEvent } from '../../../../models/Event';
import { EventType } from '../../../../enums/EventType';
import { addEventDB } from '../../../../api/events/eventsService';
import { Subsidiary } from '../../../../models/Subsidiary';
import { getSubsidiary } from '../../../../api/auth/user-info';

interface AddEventModalProps {
    onClose: () => void;
}

const AddEventModal: React.FC<AddEventModalProps> = (props) => {

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [date, setDate] = useState<DateValue | null>(null);
    const [endDate, setEndDate] = useState<DateValue | null>(null);
    const [nameError, setNameError] = useState<string>('');
    const [descriptionError, setDescriptionError] = useState<string>('');
    const [dateError, setDateError] = useState<string>('');
    const [endDateError, setEndDateError] = useState<string>('');
    const [endDateAvailable, setEndDateAvailable] = useState<boolean>(false);

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const handleAddEvent = async () => {
        let error = false;
        // Validatie kan hier worden toegevoegd
        if (name === '') {
            setNameError('Naam is verplicht.');
            error = true;
        } else {
            setNameError('');
        }

        if (date === null) {
            setDateError('Datum is verplicht.');
            error = true;
        } else {
            const corrDate = new Date(`${date!.year}-${String(date!.month).padStart(2, '0')}-${String(date!.day).padStart(2, '0')}`).setHours(0, 0, 0, 0);
            if (corrDate < new Date().setHours(0, 0, 0, 0)) {
                setDateError('Datum mag niet in het verleden liggen.');
                error = true;
                console.log('Date:', corrDate);
            } else {
                setDateError('');
            }
        }

        if (endDateAvailable) {
            if (endDate === null) {
                setEndDateError('Einddatum is verplicht.');
                error = true;
            } else {
                const corrDate = new Date(`${date!.year}-${String(date!.month).padStart(2, '0')}-${String(date!.day).padStart(2, '0')}`);
                const corrEndDate = new Date(`${endDate!.year}-${String(endDate!.month).padStart(2, '0')}-${String(endDate!.day).padStart(2, '0')}`);
                if (corrEndDate < corrDate) {
                    setEndDateError('Einddatum mag niet voor de startdatum liggen.');
                    error = true;
                } else {
                    setEndDateError('');
                }
            }
        }

        if (!error) {
            const subsidiary: Subsidiary = getSubsidiary();
            const dateString = `${date!.year}-${String(date!.month).padStart(2, '0')}-${String(date!.day).padStart(2, '0')}`;
            const event: CreateEvent = {
                name: name,
                date: dateString,
                event_type: EventType.CREATED_EVENTS,
                subsidiary_id: subsidiary.id,
            }

            try {
                const response = await addEventDB(event);
                console.log('Response:', response);

            } catch (error) {

            }

            props.onClose();
        }
    };

    return (
        <div className='add-event-modal-overlay' onClick={props.onClose}>
            <div className="add-event-modal-wrapper">
                <div className="add-event-modal-container" onClick={stopPropagation}>
                    <div className="add-event-modal-top-area">
                        <div className="add-event-modal-top-inner-title-area">
                            <h2 className="add-event-modal-title">Voeg Evenement Toe</h2>
                        </div>
                        <div className="add-event-modal-top-inner-close-area">
                            <button className="add-event-modal-close-btn" onClick={props.onClose} type='button'>
                                <span className="material-symbols-outlined">close</span>
                            </button>
                        </div>
                    </div>
                    <div className="add-event-modal-content">
                        <Input
                            className='add-event-modal-input'
                            type="text"
                            variant="bordered"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                if (e.target.value !== '') setNameError('');
                            }}
                            label="Naam"
                        />
                        <Textarea
                            className='add-event-modal-input'
                            variant="bordered"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            label="Beschrijving"
                            minRows={3}
                        />
                        <DatePicker
                            className='add-event-pre-briefing-modal-input'
                            label="Startdatum"
                            variant="bordered"
                            onChange={(value: DateValue) => setDate(value)}
                            value={date}
                            showMonthAndYearPickers
                            errorMessage={dateError}
                            isInvalid={dateError !== ''}
                        />
                        <p className="add-event-pre-briefing-enddate-txt-p">Heeft het evenement een einddatum?</p>
                        <Switch
                            className='add-event-pre-briefing-modal-input'
                            isSelected={endDateAvailable}
                            onChange={() => setEndDateAvailable(!endDateAvailable)}
                        />
                        <DatePicker
                            className='add-event-pre-briefing-modal-input'
                            label="Einddatum"
                            variant="bordered"
                            isDisabled={!endDateAvailable}
                            value={endDate}
                            onChange={(value: DateValue) => setEndDate(value)}
                            showMonthAndYearPickers
                            errorMessage={endDateError}
                            isInvalid={endDateError !== ''}
                        />
                        <div className="add-event-modal-controls-area">
                            <button
                                className="add-event-modal-add-btn"
                                type='button'
                                onClick={handleAddEvent}
                            >
                                Voeg toe
                            </button>
                            <button
                                className="add-event-modal-cancel-btn"
                                type='button'
                                onClick={props.onClose}
                            >
                                Annuleren
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEventModal;
