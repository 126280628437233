import React, { useEffect, useState } from 'react';

interface WeatherWidgetSmallProps {
    city: string;
}

const WeatherWidgetSmall: React.FC<WeatherWidgetSmallProps> = (props) => {
    const [weatherData, setWeatherData] = useState<any>(null);

    useEffect(() => {
        const fetchWeather = async () => {

            const apiKey = process.env.REACT_APP_OPENWEATHER_API_KEY;

            // First, fetch the coordinates for the city
            const cityResponse = await fetch(`https://api.openweathermap.org/geo/1.0/direct?q=${props.city}&appid=${apiKey}`);
            const cityData = await cityResponse.json();
            const lat = cityData[0].lat;
            const lon = cityData[0].lon;

            try {
                const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&lang=nl&units=metric`);
                const data = await response.json();
                console.log('Weather data:', data);
                setWeatherData(data);
            } catch (error) {
                console.error('Error fetching weather data:', error);
            }
        };

        fetchWeather();
    }, []);

    if (!weatherData) {
        return <div>Loading...</div>;
    }

    const { main, weather } = weatherData;
    const temperature = main.temp;
    const minTemp = main.temp_min;
    const maxTemp = main.temp_max;
    const weatherDescription = weather[0].description.charAt(0).toUpperCase() + weather[0].description.slice(1);
    const weatherIcon = weather[0].icon;

    let weatherClass = "quick-item weather-item";
    if (weatherIcon.includes('n')) {
        weatherClass += " night";
    } else if (weather[0].main === "Rain") {
        weatherClass += " rain";
    } else if (weather[0].main === "Clouds") {
        weatherClass += " clouds";
    } else if (weather[0].main === "Clear") {
        weatherClass += " sun";
    } else if (weather[0].main ===  'Thunderstorm') {
        weatherClass += " thunderstorm";
    } else if (weather[0].main === 'Drizzle') {
        weatherClass += " drizzle";
    } else if (weather[0].main === 'Snow') {
        weatherClass += " snow";
    } else if (weather[0].main === "Atmosphere" || weather[0].main === "Mist" || weather[0].main === "Smoke" || weather[0].main === "Haze" || weather[0].main === "Dust" || weather[0].main === "Fog" || weather[0].main === "Sand" || weather[0].main === "Ash" || weather[0].main === "Squall" || weather[0].main === "Tornado") {
        weatherClass += " atmosphere";
    }

    const date = new Date();
    const dateOptions: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
    const dateString = date.toLocaleDateString('nl-NL', dateOptions);

    return (
        <div className={weatherClass}>
            <p className="quick-button-txt-p">Vandaag, {dateString}</p>
            <p className="quick-button-txt-p weather-txt">{Math.round(temperature)}°C</p>
            <div className="under-info-div">
                <img
                    className="weather-icon-span"
                    src={`http://openweathermap.org/img/wn/${weatherIcon}.png`}
                    alt={weatherDescription}
                />
                <p className="weather-under-txt-p">{weatherDescription}</p>
                <p className="weather-minmax-txt-p">
                    <span className="weather-minmax-span">min {Math.round(minTemp)}°C</span>
                    <span className="weather-minmax-span">max {Math.round(maxTemp)}°C</span>
                </p>
            </div>
        </div>
    );
};

export default WeatherWidgetSmall;

