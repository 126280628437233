import React, { useEffect } from 'react';
import './ViewAddCreatedThings.css';
import { fetchThingsByThingType } from '../../../../api/things/thingsService';
import AddThingItem from '../ViewCreatedThings/AddThingItem/AddThingItem';
import CreatedAddThingItem from './CreatedAddThingItem/CreatedAddThingItem';

interface ViewAddCreatedThingsProps {
    thingtype: { id: number; plural: string, singular: string, color: string, };
    onAddThingClick: () => void;
    refreshKey: number;
    onPlanThingClick: (thing: any) => void;
    selectedThings: any[];
}

const ViewAddCreatedThings: React.FC<ViewAddCreatedThingsProps> = ({ thingtype, onAddThingClick, refreshKey, onPlanThingClick, selectedThings }) => {
    const [things, setThings] = React.useState<any[]>([]);

    useEffect(() => {
        const loadThings = async () => {
            try {
                const things = await fetchThingsByThingType(thingtype.id);
                setThings(things);
            } catch (err) {
                console.error('Failed to fetch things:', err);
            } finally {
                console.log('Done fetching things');
            }
        };
        loadThings();
    }, [refreshKey]);

    return (
        <div id="view-created-things-container">
            <ul className="created-things-list">
                <AddThingItem thingtype={thingtype} onAddThingClick={onAddThingClick} />
                {things.map((thing, index) => (<CreatedAddThingItem key={index} thing={thing} thingtype={thingtype} onPlanThingClick={onPlanThingClick} selected={selectedThings.includes(thing)} />))}
            </ul>
        </div>
    );
};

export default ViewAddCreatedThings;