import React from 'react';
import './ReportThingsList.css';

interface ReportThingsListProps {
    actions: any[];
    onClickAction: (action: any) => void;
}

const ReportThingsList: React.FC<ReportThingsListProps> = (props) => {
    return (
        <div className='reportthings-wrapper'>
            <div className="reportthings-list-column">
                <h1 className='reportthings-title-h1'>To do</h1>
                <ul className='reportthings-listing-ul'>
                    {props.actions.filter((value, index) => value.completed_on === null).map((action, index) => (
                        <li className="thinglist-listing-li" style={{ backgroundColor: action.thing_type.color }} onClick={() => props.onClickAction(action)}>
                            <div className="thinglist-title-area-div">
                                <h2 className="thinglist-title-h2">{action.title}</h2>
                            </div>
                            <hr />
                            <div className="thinglist-info-area-div">
                                <p className="thinglist-info-p">{action.description}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                {props.actions.filter((value, index) => value.completed_on === null).length === 0 && <p className='nothings-txt-p'>Geen things te doen</p>}
            </div>
            <div className="reportthings-list-column">
                <h1 className='reportthings-title-h1'>Done</h1>
                <ul className='reportthings-listing-ul'>
                    {props.actions.filter((value, index) => value.completed_on !== null).map((action, index) => (
                        <li className="thinglist-listing-li" style={{ backgroundColor: action.thing_type.color }} onClick={() => props.onClickAction(action)}>
                            <div className="thinglist-title-area-div">
                                <h2 className="thinglist-title-h2">{action.title}</h2>
                            </div>
                            <hr />
                            <div className="thinglist-info-area-div">
                                <p className="thinglist-info-p">{action.description}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                {props.actions.filter((value, index) => value.completed_on !== null).length === 0 && <p className='nothings-txt-p'>Geen things voltooid</p>}
            </div>
        </div>
    );
};

export default ReportThingsList;