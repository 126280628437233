import React from 'react';

const PreOrderView: React.FC = () => {
    return (
        <div>
            <h1>Pre-Order</h1>
        </div>
    );
};

export default PreOrderView;