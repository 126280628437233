import { MenuType } from "../../models/MenuType";
import api from "../auth/api";

export const fetchMenuTypes = async (): Promise<MenuType[]> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };
    try {
        const response = await api.get('/menu_types/', config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch menu types:', error);
        throw error;
    }
}