import React from 'react';
import './UploadMenu.css';
import { MenuType } from '../../../../../models/MenuType';
import { fetchMenuTypes } from '../../../../../api/MenuType/MenuTypeServices';
import { useDropzone } from 'react-dropzone';
import * as pdfjsLib from 'pdfjs-dist';
import { Select, SelectItem } from '@nextui-org/react';
pdfjsLib.GlobalWorkerOptions.workerSrc = `/assets/pdfLib/pdf.worker.min.mjs`;

interface UploadMenuProps {
    menuTypes: MenuType[];
    setMenuTypes: (menuTypes: MenuType[]) => void;
    selectedMenuType: string;
    setSelectedMenuType: (menuType: string) => void;
    file: any;
    setFile: (file: any) => void;
    pdfDataUrl: string | null;
    setPdfDataUrl: (pdfDataUrl: string | null) => void;
    errorSelectedMenuType: string;
}

const UploadMenu: React.FC<UploadMenuProps> = ({ menuTypes, setMenuTypes, selectedMenuType, setSelectedMenuType, file, setFile, pdfDataUrl, setPdfDataUrl, errorSelectedMenuType}) => {
    // Add your component logic and JSX here

    const changeSelectedMenuType = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        console.log('Selected Menu Type:', Number(value));
        setSelectedMenuType(value);
    }

    const onDrop = (acceptedFiles: any) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setFile(file);
            const reader = new FileReader();
            reader.onload = async () => {
                const typedArray = new Uint8Array(reader.result as ArrayBuffer);
                const pdf = await pdfjsLib.getDocument(typedArray).promise;
                const page = await pdf.getPage(1);
                const viewport = page.getViewport({ scale: 1 });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                if (context) {
                    await page.render({ canvasContext: context, viewport: viewport }).promise;
                }
                setPdfDataUrl(canvas.toDataURL());
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
        },
        multiple: false,
        noClick: true,
        noKeyboard: true
    });

    return (
        <div className='add-subsidiary-upload-menu-view'>
            <div className="menuupload-modal-upload-area">

                <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                    <input {...getInputProps()} />
                    {
                        file ? (
                            <div>
                                <button onClick={() => setFile(null)} className="menuupload-close-button" type='button'>
                                    <span className="menuupload-close-icon-span material-symbols-outlined">close</span>
                                </button>
                                {pdfDataUrl && <img src={pdfDataUrl} alt="PDF Thumbnail" width="100%" />}
                            </div>
                        ) : (
                            isDragActive ?
                                <div className="menu-upload-drop-area">
                                    <p className="menu-upload-drop-txt-p">Drop it like it's hot</p>
                                </div> :
                                <div className="menu-upload-add-file-inner-area">
                                    <span className="menu-upload-file-icon-span material-symbols-outlined">upload</span>
                                    <p className="menu-upload-add-file-txt-p">Sleep je bestanden hier of <a onClick={open} className='files-browse-a'>blader hier</a></p>
                                </div>

                        )
                    }
                </div>
            </div>
            <Select
                variant="bordered"
                label="Selecteer een menutype"
                className="max-w select-menutype bg-white"
                value={selectedMenuType ?? ''}
                onChange={changeSelectedMenuType}
                selectedKeys={[selectedMenuType]}
                isDisabled={file === null}
                isInvalid={errorSelectedMenuType !== ''}
                errorMessage={errorSelectedMenuType}
            >
                {menuTypes.map((menuType) => (
                    <SelectItem key={menuType.id} value={menuType.id}>
                        {menuType.name}
                    </SelectItem>
                ))}
            </Select>
        </div>
    );
};

export default UploadMenu;