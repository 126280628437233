import React from 'react';
import './CreatedThingItem.css';
import { Thing } from '../../../../../models/Thing';
import { ThingType } from '../../../../../models/ThingType';

interface CreatedThingItemProps {
    thing: Thing;
    thingtype: ThingType;
    onPlanThingClick: (thing: any) => void;
    onClickThingOptions: (event: React.MouseEvent, thing: any) => void;
}

const CreatedThingItem: React.FC<CreatedThingItemProps> = (props) => {

    const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        console.log('Options clicked');
        props.onClickThingOptions(event, props.thing);
    };

    return (
        <div className="created-thing-item" style={{backgroundColor: props.thingtype.color}} onClick={() => props.onPlanThingClick(props.thing)}>
            <h2 className='created-thing-title-h2'>{props.thing.title}</h2>
            <hr className="created-thing-divder" />
            <p className='created-thing-txt-p'>{props.thing.description}</p>
            <div className="created-thing-item-options">
                <button className="created-thing-item-options-btn" onClick={handleOptionsClick}>
                    <span className="material-symbols-rounded">more_vert</span>
                </button>
            </div>
        </div>
    );
};

export default CreatedThingItem;