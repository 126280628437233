import React, { useEffect, useState } from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';
import ReportThingsList from '../../../components/Portaal/Report/ReportThingsList/ReportThingsList';
import { fetchActionsByDate } from '../../../api/actions/actionsService';
import ModalLayout from '../../../components/Portaal/ModalLayout/ModalLayout';
import CompleteThingModal from '../../../components/Portaal/Things/CompleteThingModal/CompleteThingModal';
import './ReportView.css';
import ReportNotesList from '../../../components/Portaal/Report/ReportNotesList/ReportNotesList';
import { fetchNotes } from '../../../api/notes/notesService';
import NoteOverlay from '../../../components/Portaal/Dashboard/NoteOverlay/NoteOverlay';
import SendReportModal from '../../../components/Portaal/Report/SendReportModal/SendReportModal';
import { fetchReportKPIs } from '../../../api/day_reports/day_reportsService';

interface ReportViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
}

const ReportView: React.FC<ReportViewProps> = ( props ) => {
    const [actions, setActions] = React.useState<any[]>([]);
    const [date, setDate] = React.useState<Date>(new Date());
    const formattedDate = date.toISOString().slice(0, 10);

    useEffect(() => {

        const loadActions = async () => {
            try {
                const actions = await fetchActionsByDate(formattedDate);
                setActions(actions);
            } catch (err) {
                console.error('Failed to fetch actions:', err);
            } finally {
                console.log('Done fetching actions');
            }
        };

        loadActions();

    }, []);

    const [notes, setNotes] = React.useState<any[]>([]);	

    useEffect(() => {
        const loadNotes = async () => {
            try {
                const notes = await fetchNotes();
                setNotes(notes);
            } catch (err) {
                console.error('Failed to fetch notes:', err);
            } finally {
                console.log('Done fetching notes');
            }
        };

        loadNotes();
    }, []);

    const [reportKPIs, setReportKPIs] = React.useState<any[]>([]);
    useEffect(() => {
        const fetchKPIs = async () => {
            try {
                const reportKPIs = await fetchReportKPIs();
                console.log(reportKPIs);
                setReportKPIs(reportKPIs);
            } catch (err) {
                console.error('Failed to fetch KPIs:', err);
            } finally {
                console.log('Done fetching KPIs');
            }
        }
        fetchKPIs();
    }, []);

    const [openCompleteThingModal, setOpenCompleteThingModal] = useState<boolean>(false);

    const [selectedAction, setSelectedAction] = useState<any>(undefined);

    const closeCompleteThingModal = () => {
        setOpenCompleteThingModal(false);
    };

    const onClickAction = (action: any) => {
        console.log(action);
        setSelectedAction(action);
        setOpenCompleteThingModal(true);
    }

    const [openNoteOverlay, setOpenNoteOverlay] = useState<boolean>(false);
    const [selectedNote, setSelectedNote] = useState<any>(undefined);

    const onClickNote = (note: any) => {
        console.log('Note:', note);
        setSelectedNote(note);
        setOpenNoteOverlay(true);
    };

    const updateNote = (note: any) => {
        const updatedNotes = notes.map((n: any) => {
            if (n.id === note.id) {
                return { ...n, ...note };
            }
            return n;
        });
        setNotes(updatedNotes);
    }

    const onDeleteNote = (note_id: number) => {
        const updatedNotes = notes.filter((n: any) => n.id !== note_id);
        setNotes(updatedNotes);
    }

    const onAddNote = (note: any) => {
        setNotes([note, ...notes]);
    }

    const [openSendReportModal, setOpenSendReportModal] = useState<boolean>(false);

    useEffect(() => {
        console.log('Report KPIs:', reportKPIs);
    }, [reportKPIs]);

    return (
        <div>
            {openSendReportModal && <ModalLayout isOpen={openSendReportModal} onClose={() => setOpenSendReportModal(false)} title='Verstuur rapport'>
                <SendReportModal reportKPIs={reportKPIs} onClose={() => setOpenSendReportModal(false)} notes={notes} actions={actions}/>
            </ModalLayout>}
            {openNoteOverlay && <NoteOverlay isOpen={openNoteOverlay} note={selectedNote} onClose={() => setOpenNoteOverlay(false)} updateNote={updateNote} onAddNote={onAddNote} onDeleteNote={onDeleteNote} readOnly={true} />}
            {openCompleteThingModal && <ModalLayout isOpen={openCompleteThingModal} onClose={() => setOpenCompleteThingModal(false)}>
                <CompleteThingModal action={selectedAction} closeCompleteThingModal={closeCompleteThingModal} />
            </ModalLayout>}
            <PortaalPageLayout activePage='rapport' isNavOpen={props.isNavOpen} toggleNav={props.toggleNav}>
                <ReportThingsList actions={actions} onClickAction={onClickAction} />
                <ReportNotesList notes={notes} onClickNote={onClickNote} />
                <div className="reportthings-controls">
                    <button className="reportthings-send-report-btn" onClick={() => setOpenSendReportModal(true)}>
                        <span className='reportthings-send-txt'>Verstuur rapport</span>
                        <span className="material-symbols-outlined reportthings-send-report-icon">send</span>
                    </button>
                </div>
            </PortaalPageLayout>
        </div>
    );
};

export default ReportView;