import React, { useState } from 'react';
import logo from '../../../../assets/img/website-10things-logo-v2-outlined.png';
import { Link } from 'react-router-dom';
import './AccountInfo.css';
interface AccountInfoProps {
    step: number;
    setStep: (step: number) => void;
    fname: string;
    setFname: (fname: string) => void;
    fnameError: string;
    lname: string;
    setLname: (lname: string) => void;
    lnameError: string;
    email: string;
    setEmail: (email: string) => void;
    emailError: string;
    phone: string;
    setPhone: (phone: string) => void;
    phoneError: string;
    password: string;
    setPassword: (password: string) => void;
    passwordError: string;
    confirmPassword: string;
    setConfirmPassword: (confirmPassword: string) => void;
    confirmPasswordError: string;
    isChecked: boolean;
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isCheckedError: string;
    handleAccountInfoValidation: (event: React.FormEvent<HTMLFormElement>) => void;
}

const AccountInfo: React.FC<AccountInfoProps> = (props) => {
    const handleBackClick = () => {
        props.setStep(props.step - 1);
    };
    return (
        <div className='accountinfo-content-div'>
            <nav className="register-top-nav">
                <ul className="register-top-nav-listing-ul">
                    <li className="register-top-left-area-li">
                        <button className="register-back-btn" type='button' onClick={handleBackClick}>
                            <span className="register-back-btn-icon-span material-symbols-outlined">
                                arrow_back
                            </span>
                        </button>
                    </li>
                    <li className="register-top-middle-area">
                        <img src={logo} alt="10-Things" className="register-logo-img" />
                    </li>
                    <li className="register-top-right-area-li">
                    </li>
                </ul>
            </nav>
            <div className="register-inner-wrapper-div">
                <div className="register-inner-container-div">
                    <form className="register-form" onSubmit={props.handleAccountInfoValidation}>
                        <div className="register-input-bar">
                            <label htmlFor="firstname" className="register-label">Voornaam<span className="register-required-span">*</span></label>
                            <input type="text" placeholder="Vul je naam in" name='firstname' value={props.fname} onChange={(event) => props.setFname(event.target.value)} className="register-input" />
                            {props.fnameError ? <p className="register-err-txt-p">{props.fnameError}</p> : null}
                        </div>
                        <div className="register-input-bar">
                            <label htmlFor="lastname" className="register-label">Achternaam<span className="register-required-span">*</span></label>
                            <input type="text" placeholder="Vul je achternaam in" name='lastname' value={props.lname} onChange={(event) => props.setLname(event.target.value)} className="register-input" />
                            {props.lnameError ? <p className="register-err-txt-p">{props.lnameError}</p> : null}
                        </div>
                        <div className="register-input-bar">
                            <label htmlFor="tel" className="register-label">Telefoon<span className="register-required-span">*</span></label>
                            <input type="text" placeholder="Vul je telefoonnummer in" name='tel' value={props.phone} onChange={(event) => props.setPhone(event.target.value)} className="register-input" />
                            {props.phoneError ? <p className="register-err-txt-p">{props.phoneError}</p> : null}
                        </div>
                        <div className="register-input-bar">
                            <label htmlFor="email" className="register-label">E-mail<span className="register-required-span">*</span></label>
                            <input type="text" placeholder="Vul je e-mail in" name='email' value={props.email} onChange={(event) => props.setEmail(event.target.value)} className="register-input" />
                            {props.emailError ? <p className="register-err-txt-p">{props.emailError}</p> : null}
                        </div>
                        <div className="register-input-bar">
                            <label htmlFor="password" className="register-label">Wachtwoord<span className="register-required-span">*</span></label>
                            <input
                                type="password"
                                placeholder="Vul je wachtwoord in"
                                name="password"
                                value={props.password}
                                onChange={(event) => props.setPassword(event.target.value)}
                                className="register-input"
                                autoComplete="new-password"
                            />
                            {props.passwordError ? <p className="register-err-txt-p">{props.passwordError}</p> : null}
                        </div>
                        <div className="register-input-bar">
                            <label htmlFor="cpassword" className="register-label">Bevestig wachtwoord<span className="register-required-span">*</span></label>
                            <input
                                type="password"
                                placeholder="Bevestig je wachtwoord"
                                name="cpassword"
                                value={props.confirmPassword}
                                onChange={(event) => props.setConfirmPassword(event.target.value)}
                                className="register-input"
                                autoComplete="new-password"
                            />
                            {props.confirmPasswordError ? <p className="register-err-txt-p">{props.confirmPasswordError}</p> : null}
                        </div>
                        <div className="register-input-bar">
                            <label className="register-label">
                                <input type="checkbox" name="terms" className="register-checkbox" checked={props.isChecked} onChange={props.handleCheckboxChange} required />
                                <span className="register-checkbox-custom"></span>
                                <p className="register-checkbox-txt-p">Ik ga akkoord met de <a href="/algemene-voorwaarden" target="_blank" className="register-link">gebruiksvoorwaarden</a> en <a href="/privacy-overeenkomst" target="_blank" className="register-link">privacybeleid</a><span className="register-required-span">*</span></p>
                            </label>
                            {props.isCheckedError ? (<p className="register-err-txt-p">{props.isCheckedError}</p>) : null}
                        </div>
                        <button className="register-next-btn" type='submit'>Account aanmaken</button>
                    </form>
                </div>

            </div>
        </div>
    );
};

export default AccountInfo;