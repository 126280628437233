import React from 'react';
import './PopUpLayout.css';

interface PopUpLayoutProps {
    // Define the props for the PopUpLayout component here
    title: string;

    onClose: () => void;
    children: React.ReactNode;
}

const PopUpLayout: React.FC<PopUpLayoutProps> = (props) => {
    // Implement the logic for the PopUpLayout component here

    return (
        <div className="popup-overlay">
            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="popup-top-bar">
                        <p className="popup-title-txt-p">{props.title}</p>
                    </div>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default PopUpLayout;