import axios from 'axios';

import { refreshAccessToken } from '../api/auth/user.auth';
import { backendURL } from '../devEnv';

let headers: { [key: string]: string } = {};
const tokenInfo = JSON.parse(localStorage.getItem('tokens')!);

if (tokenInfo)
    headers['Authorization'] = `Bearer ${tokenInfo.access_token}`;

const backend = axios.create({
    baseURL: backendURL,
    headers: headers
});

let isRefreshing = false;
let refreshQueue: any[] = [];

backend.interceptors.request.use(config => {
    const tokenInfo = JSON.parse(localStorage.getItem('tokens')!)
    if (tokenInfo) {
        config.headers['Authorization'] = `Bearer ${tokenInfo.access_token}`;
    } else {
        delete config.headers['Authorization'];
    }
    return config;
});

backend.interceptors.response.use(
    response => response, // Simply return the response if everything is fine
    async error => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                // If refresh is already in progress, queue the request and wait
                return new Promise((resolve, reject) => {
                    refreshQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers.Authorization = `Bearer ${token}`;
                        return axios(originalRequest); // Retry the original request with new token
                    })
                    .catch(err => {
                        originalRequest._retry = true; // Mark the request as retried
                        return axios(originalRequest); // Retry the original request
                    });
            }

            originalRequest._retry = true; // Mark the request as retried
            isRefreshing = true;

            try {
                const newAccessToken = await refreshAccessToken();
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                backend.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
                refreshQueue.forEach(callback => callback.resolve(newAccessToken));
                refreshQueue = [];
                return axios(originalRequest); // Retry the original request with new token
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                refreshQueue.forEach(callback => callback.reject(refreshError));
                refreshQueue = [];
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }
        return Promise.reject(error);
    }
);

export default backend;