import React, { useEffect } from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';
import CreatedThingsList from '../../../components/Portaal/Things/CreatedThingsList/CreatedThingsList';
import ViewCreatedThings from '../../../components/Portaal/Things/ViewCreatedThings/ViewCreatedThings';
import { checkLocalStorageItems } from '../../../api/auth/user.auth';
import { useNavigate } from 'react-router-dom';
import { fetchThingTypes, removeThing } from '../../../api/things/thingsService';
import ModalLayout from '../../../components/Portaal/ModalLayout/ModalLayout';
import AddThingModal from '../../../components/Portaal/Things/AddThingModal/AddThingModal';
import PlanThingModal from '../../../components/Portaal/Things/PlanThingModal/PlanThingModal';
import './ThingsView.css';
import OptionsModal from '../../../components/Portaal/OptionsModal/OptionsModal';
import { Thing } from '../../../models/Thing';
import { ModalOption } from '../../../models/ModalOption';
import DeletePopup from '../../../components/Portaal/DeletePopup/DeletePopup';
import EditThingModal from '../../../components/Portaal/Things/EditThingModal/EditThingModal';
import { Helmet } from 'react-helmet-async';
import usePageTracking from '../../../utils/usePageTracking';

interface ThingsViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
}

const ThingsView: React.FC<ThingsViewProps> = (props) => {
    usePageTracking();
    const [refreshKey, setRefreshKey] = React.useState<number>(0);
    const [thingTypes, setThingTypes] = React.useState<any[]>([]);
    const [thingType, setThingType] = React.useState<any | null>(null);
    const [enableBackButton, setEnableBackButton] = React.useState<boolean>(false);
    const onThingTypeClick = (thingType: any) => {
        setEnableBackButton(true);
        setThingType(thingType);
    };

    const onBackButtonClick = () => {
        setEnableBackButton(false);
        setThingType(null);
    };

    const [openAddThingModal, setOpenAddThingModal] = React.useState<boolean>(false);

    const onAddThingClick = () => {
        setOpenAddThingModal(true);
        console.log('Add thing clicked');
    };

    const [openPlanThingModal, setOpenPlanThingModal] = React.useState<boolean>(false);
    const [selectedPlanThing, setSelectedPlanThing] = React.useState<any>(null);

    const onPlanThingClick = (thing: any) => {
        setOpenPlanThingModal(true);
        setSelectedPlanThing(thing);
        console.log(`Plan thing clicked with id: ${thing.id}`);
    };

    const navigate = useNavigate();

    checkLocalStorageItems().then((result) => {
        console.log(result);
        if (!result) {
            navigate('/login');
        }
    }
    );

    useEffect(() => {
        const loadThingTypes = async () => {
            try {
                const thingTypes = await fetchThingTypes();
                setThingTypes(thingTypes);
            } catch (err) {
                console.error('Failed to fetch thing types:', err);
            } finally {
                console.log('Done fetching thing types');
            }
        };

        loadThingTypes();
    }, []);

    const refreshThings = () => {
        setRefreshKey(oldKey => oldKey + 1); // Update refreshKey to trigger a refresh
    };

    const [openOptionsView, setOpenOptionsView] = React.useState<boolean>(false);
    const [optionsX, setOptionsX] = React.useState<number>(0);
    const [optionsY, setOptionsY] = React.useState<number>(0);
    const [activeThing, setActiveThing] = React.useState<Thing | null>(null);
    const handleThingEdit = () => {
        setOpenOptionsView(false);
        setOpenEditThingModal(true);
    };

    const handleThingDelete = () => {
        setOpenOptionsView(false);
        setOpenDeletePopup(true);
    };
    const options: ModalOption[] = [
        {
            label: 'Bewerk',
            icon: 'edit',
            action: handleThingEdit,
            class: ''
        },
        {
            label: 'Verwijder',
            icon: 'delete',
            action: handleThingDelete,
            class: 'delete'
        }
    ];

    const onThingOptionsClick = (event: React.MouseEvent, thing: Thing) => {
        event.stopPropagation();
        const rect = (event.target as HTMLElement).getBoundingClientRect();
        setOptionsX(rect.left);
        setOptionsY(rect.bottom);
        setOpenOptionsView(true);
        setActiveThing(thing);
    };

    const [openDeletePopup, setOpenDeletePopup] = React.useState<boolean>(false);

    const handleDeleteThing = async () => {
        await removeThing(activeThing!.id).then(() => {
            refreshThings();
            setOpenDeletePopup(false);
        }
        );
    };

    const [openEditThingModal, setOpenEditThingModal] = React.useState<boolean>(false);

    return (
        <div>
            <Helmet>
                <title>10-Things | Portaal | Things</title>
            </Helmet>
            {openEditThingModal && <ModalLayout isOpen={openEditThingModal} title={'Bewerk ' + activeThing?.title} onClose={() => setOpenEditThingModal(false)} >
                <EditThingModal thing={activeThing!} thingtype={thingType} closeEditThingModal={() => setOpenEditThingModal(false)} refreshThings={refreshThings} />
            </ModalLayout>}
            {openDeletePopup && <DeletePopup title='Weet je zeker dat je deze thing wilt verwijderen?' text='Je kunt dit niet meer terugdraaien!' onDelete={handleDeleteThing} onClose={() => setOpenDeletePopup(false)} />}
            {openOptionsView && <OptionsModal onClose={() => setOpenOptionsView(false)} x={optionsX} y={optionsY} options={options} />}
            {thingType && <ModalLayout isOpen={openAddThingModal} title={'Voeg een ' + thingType.singular.toLowerCase() + ' toe'} onClose={() => setOpenAddThingModal(false)} >
                <AddThingModal closeAddThingModal={() => setOpenAddThingModal(false)} thingtype={thingType} refreshThings={refreshThings} />
            </ModalLayout>}
            {selectedPlanThing && <ModalLayout isOpen={openPlanThingModal} title={'Plan ' + selectedPlanThing.title} onClose={() => setOpenPlanThingModal(false)} >
                <PlanThingModal closePlanThingModal={() => setOpenPlanThingModal(false)} thingtype={thingType} thing={selectedPlanThing} />
            </ModalLayout>}
            <PortaalPageLayout activePage='things' onBackButtonClick={onBackButtonClick} enableBackButton={enableBackButton} isNavOpen={props.isNavOpen} toggleNav={props.toggleNav}>
                {thingType && <div className="viewcreated-things-wrapper"><ViewCreatedThings thingtype={thingType} onAddThingClick={onAddThingClick} onPlanThingClick={onPlanThingClick} refreshKey={refreshKey} onClickThingOptions={onThingOptionsClick} /></div>}
                {!thingType && <CreatedThingsList thingtypes={thingTypes} onThingTypeClick={onThingTypeClick} onThingOptionsClick={onThingOptionsClick} />}
            </PortaalPageLayout>
        </div>
    );
};

export default ThingsView;