import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import './PDFViewerModal.css'; // Create this CSS file for styling
import { RenderParameters } from 'pdfjs-dist/types/src/display/api';

pdfjsLib.GlobalWorkerOptions.workerSrc = `/assets/pdfLib/pdf.worker.min.mjs`;

interface PDFViewerModalProps {
    url: string;
    onClose: () => void;
    onClick: (e: React.MouseEvent) => void;
}

const PDFViewerModal: React.FC<PDFViewerModalProps> = ({ url, onClose, onClick }) => {
    const [pdf, setPdf] = useState<pdfjsLib.PDFDocumentProxy | null>(null);
    const isMounted = useRef(true);
    const renderTasksRef = useRef<pdfjsLib.RenderTask[]>([]);

    useEffect(() => {
        isMounted.current = true;

        const loadPDF = async () => {
            const loadingTask = pdfjsLib.getDocument(url);
            const pdf = await loadingTask.promise;
            if (isMounted.current) {
                setPdf(pdf);
                renderAllPages(pdf);
            }
        };

        loadPDF();

        return () => {
            isMounted.current = false;
            renderTasksRef.current.forEach(task => task.cancel());
            renderTasksRef.current = [];
        };
    }, [url]);

    let isRendering = false;

    const renderAllPages = async (pdf: pdfjsLib.PDFDocumentProxy) => {
        if (isRendering) {
            console.log("Rendering is already in progress");
            return;  // Prevent duplicate invocations
        }

        isRendering = true;

        const container = document.getElementById('pdf-container');
        if (container) {
            container.innerHTML = ''; // Clear previous content
            renderTasksRef.current.forEach(task => task.cancel());
            renderTasksRef.current = [];

            console.log('Total number of pages:', pdf.numPages);

            for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                console.log(`Requesting page ${pageNumber} of ${pdf.numPages}`);

                try {
                    const page = await pdf.getPage(pageNumber);
                    console.log(`Successfully retrieved page: ${pageNumber}`);

                    const viewport = page.getViewport({ scale: 1 });

                    const canvas = document.createElement('canvas');
                    canvas.className = 'pdf-viewer-page';
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;
                    container.appendChild(canvas);

                    const context = canvas.getContext('2d');
                    const renderContext = {
                        canvasContext: context,
                        viewport,
                    };

                    const renderTask = page.render(renderContext as RenderParameters);
                    renderTasksRef.current.push(renderTask);

                    await renderTask.promise.catch((error) => {
                        if (error.name !== 'RenderingCancelledException') {
                            console.error('Rendering error:', error);
                        }
                    });
                } catch (error) {
                    console.error(`Failed to retrieve or render page ${pageNumber}:`, error);
                }
            }
        }

        isRendering = false;
    };

    const handleClose = () => {
        isMounted.current = false;
        renderTasksRef.current.forEach(task => task.cancel());
        renderTasksRef.current = [];
        onClose();
    };

    return (
        <div className="pdf-viewer-modal-overlay" onClick={onClick}>
            <div className="pdf-modal-viewer-modal-wrapper">
                <div id="pdf-container" className="pdf-viewer-scroll-container"></div>
            </div>
            <div className="div pdf-modal-viewer-under-controls">
                <button onClick={handleClose}>
                    <span className="material-symbols-rounded pdf-modal-close-span">close</span>
                </button>
            </div>
        </div>
    );
};

export default PDFViewerModal;