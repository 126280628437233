import React from 'react';
import './TopWidgets.css';
import NotesListV2 from '../../NotesListV2/NotesListV2';
import WeatherWidgetSmall from './WeatherWidgetSmall/WeatherWidgetSmall';
import { getSubsidiary } from '../../../../api/auth/user-info';
import EventWidgetSmall from './EventWidgetSmall/EventWidgetSmall';
import TerrasWidgetSmall from './TerrasWidgetSmall/TerrasWidgetSmall';
import { Action } from '../../../../models/Action';
import VIPWidget from './VIPWidget/VIPWidget';
import { Vip, VipBriefing } from '../../../../models/Vip';
import { Shift } from '../../../../models/Shift';
import { Note } from '../../../../models/Note';

interface TopWidgetsProps {
    onClickNote: (note: any) => void;
    onClickAddNoteButton: () => void;
    onDeleteNote: (note_id: number) => void;
    notes: Note[];
    deleteActions: (action_id: number[]) => void;
    addActions: (addingActions: Action[]) => void;
    setOpenAddVIPModal: (open: boolean) => void;
    setOpenPreBriefingModal: (open: boolean) => void;
    setOpenDeBriefingModal: (open: boolean) => void;
    vips: Vip[];
    activeShift: Shift | null;
    onVipOptionsClick: (event: React.MouseEvent, vip: VipBriefing | Vip) => void;
}
const QuickButtons: React.FC<TopWidgetsProps> = (props) => {

    const subsidiary = getSubsidiary();

    const handlePreBriefingClick = () => {
        props.setOpenPreBriefingModal(true);
    };

    const handleDeBriefingClick = () => {
        props.setOpenDeBriefingModal(true);
    };

    return (
        <div id='quick-buttons-container'>
            <div className="quick-widget-lock-area">
                <div className="quick-widget-lock-grid-div">
                    {/* <div className="quick-item">
                        <div className="quick-button-icon-area">
                            <span className="quick-button-icon-span material-symbols-outlined">group</span>
                        </div>
                        <div className="quick-button-txt-area">
                            <p className="quick-button-txt-p">Voeg medewerker toe</p>
                        </div>
                    </div> */}

                    {props.activeShift === null && <div className="quick-item pre-briefing-btn" onClick={handlePreBriefingClick}>
                        <div className="quick-button-icon-area">
                            <span className="quick-button-icon-span material-symbols-outlined">add</span>
                        </div>
                        <div className="quick-button-txt-area">
                            <p className="quick-button-txt-p">Start briefing</p>
                        </div>
                    </div>}

                    {props.activeShift !== null && <div className="quick-item pre-briefing-btn" onClick={handleDeBriefingClick}>
                        <div className="quick-button-icon-area">
                            <span className="quick-button-icon-span material-symbols-outlined">check</span>
                        </div>
                        <div className="quick-button-txt-area">
                            <p className="quick-button-txt-p">Start de-briefing</p>
                        </div>
                    </div>}

                    <EventWidgetSmall />

                    <WeatherWidgetSmall city={subsidiary.city} />

                    <TerrasWidgetSmall city={subsidiary.city} />
                </div>

            </div>

            <div className="quick-widget-lock-area">
                {/* <ReportsList /> */}
                {/* <WeatherWidget city={subsidiary.city} /> */}
                <VIPWidget setOpenAddVIPModal={props.setOpenAddVIPModal} vips={props.vips} activeShift={props.activeShift} onVipOptionsClick={props.onVipOptionsClick} />
            </div>

            <div className="quick-widget-lock-area notes">
                <NotesListV2 onDeleteNote={props.onDeleteNote} onClickAddNoteButton={props.onClickAddNoteButton} onClickNote={props.onClickNote} notes={props.notes} activeShift={props.activeShift} />

            </div>
        </div>
    );
};

export default QuickButtons;