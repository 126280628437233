import { Product } from "../../models/Product";
import api from "../auth/api";

export const fetchProducts = async () => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/products/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch products:', error);
        throw error;
    }
};

export const fetchProduct = async (id: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/products/${id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch product:', error);
        throw error;
    }
};

export const fetchProductByIdentifier = async (identifier: string) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/products/by_product_identifier/${identifier}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch product:', error);
        throw error;
    }
};
    