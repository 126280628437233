import React from 'react';
import './Stepper.css';

interface StepperProps {
    steps: string[];
    currentStep: number;
}

const Stepper: React.FC<StepperProps> = ({ steps, currentStep }) => {
    return (
        <div className="stepper">
            {steps.map((step, index) => (
                <div key={index} className={`step ${currentStep > index + 1 ? 'completed' : ''} ${currentStep === index + 1 ? 'active' : ''}`}>
                    <div className="step-number">
                        {currentStep > index + 1 ? <span className="checkmark">✓</span> : index + 1}
                    </div>
                    {/* <div className="step-label">{step}</div> */}
                </div>
            ))}
        </div>
    );
};

export default Stepper;