import React, { useState } from 'react';
import { FavoriteBorder, FavoriteFilled } from '../../../../assets/icons/favorite/favoriteIcons';
import './MenuItem.css';
import { updateMenuDetail } from '../../../../api/MenuDetail/MenuDetailService';
import { MenuDetail, MenuDetailUpdate } from '../../../../models/MenuDetail';

interface MenuItemProps {
    menuDetail: MenuDetail
}

const MenuItem: React.FC<MenuItemProps> = ({ menuDetail }) => {
    const [isFilled, setIsFilled] = useState(menuDetail.priority === 1);

    const toggleFill = async () => {
        try {
            setIsFilled(!isFilled);
            let priority;
            if (!isFilled) {
                priority = 1;
            } else {
                priority = 0;
            }

            const newMenuDetail: MenuDetailUpdate = {
                menu_id: menuDetail.menu_id,
                name: menuDetail.name,
                description: menuDetail.description,
                price: menuDetail.price,
                priority: priority
            }

            console.log('NEW MENU DETAIL!!!!!!!!', newMenuDetail);

            const response = await updateMenuDetail(menuDetail.id, newMenuDetail);
            console.log(response);
        } catch (e) {
            console.error('Failed to update menu detail:', e);
        } finally {
            console.log('Done updating menu detail');
        }
    };

    return (
        <li className="menu-upload-content-list-item-li">
            <div className="menu-upload-content-fav-area">
                <button className="menu-upload-content-fav-btn" onClick={toggleFill} type='button'>
                    {isFilled ? <FavoriteFilled /> : <FavoriteBorder />}
                </button>
            </div>
            <div className="menu-upload-content-list-right-area">
                <p className="menu-upload-content-list-item-p">{menuDetail.name}</p>
                <p className="menu-upload-content-list-desc-txt-p">{menuDetail.description}</p>

            </div>
            <div className="menu-upload-content-price-area">
                <p className="menu-upload-content-list-price-txt-p">€ {menuDetail.price}</p>
            </div>
        </li>
    );
};

export default MenuItem;