import React, { useEffect, useMemo, useState } from 'react';
import './ChangeLabels.css';
import { fetchGeneralLabels, fetchLabelsByBranch } from '../../../../../../api/labels/labelsService';
import { getSubsidiary, updateSubsidiaryInfo } from '../../../../../../api/auth/user-info';
import { updateSubsidiary } from '../../../../../../api/subsidiary/subsidiaryService';

interface ChangeLabelsProps {
    // Add any props you need for the component here
    onClose: () => void;
}

const ChangeLabels: React.FC<ChangeLabelsProps> = ( props ) => {
    // Add your component logic here

    const subsidiary = getSubsidiary();

    const selectedLabelsArr = JSON.parse(subsidiary.selected_labels);

    console.log(selectedLabelsArr);

    const [labels, setLabels] = React.useState<any[]>([]);

    const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>(selectedLabelsArr);


    useEffect(() => {
        const loadLabels = async () => {
            try {
                const [generalLabels, labelsByBranch] = await Promise.all([
                    fetchGeneralLabels(),
                    fetchLabelsByBranch(subsidiary.branch_id),
                ]);
                setLabels([...generalLabels, ...labelsByBranch]);
            } catch (error) {
                console.error('Error fetching general labels:', error);
            } finally {
                console.log('Done fetching general labels');
            }
        };

        loadLabels();

    }, [subsidiary.branch_id]);

    const toggleLabelSelection = (labelId: number) => {
        setSelectedLabelIds(prev => {
            const index = prev.indexOf(labelId);
            if (index > -1) {
                return prev.filter(id => id !== labelId);
            } else {
                return [...prev, labelId];
            }
        });
    };

    const selectedLabels = useMemo(() => new Set(selectedLabelIds), [selectedLabelIds]);

    const handleSubmit = () => {
        const selectedLabelsString = JSON.stringify(Array.from(selectedLabels));

        console.log('Selected labels:', selectedLabelsString);

        updateSubsidiary(subsidiary.id, {selected_labels: selectedLabelsString});
        updateSubsidiaryInfo({selected_labels: selectedLabelsString});

        props.onClose();
    };


    return (
        <div>
            {labels.map(label => (
                <div key={label.id} className="selectable-label-btn-wrapper">
                    <button
                        className={`selectable-label-btn ${selectedLabels.has(label.id) ? 'selected' : ''}`}
                        type='button'
                        onClick={() => toggleLabelSelection(label.id)}
                    >
                        {label.value}
                    </button>
                </div>
            ))}
            <div className="selectedlabels-controls-div">
                <button className="selectedlabels-save-btn" type='button' onClick={handleSubmit}>
                    Opslaan
                </button>
                <button className="selectedlabels-cancel-btn" type='button' onClick={props.onClose}>
                    Annuleren
                </button>
            </div>
        </div>
    );
};

export default ChangeLabels;