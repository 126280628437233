import React from 'react';
import PDFViewer from '../PDFViewer/PDFViewer';

interface PDFThumbnailProps {
  url: string;
}

const PDFThumbnail: React.FC<PDFThumbnailProps> = ({ url }) => {
  return <PDFViewer url={url} />;
};

export default PDFThumbnail;