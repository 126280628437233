import { CreateSubsidiary, PatchSubsidiary, Subsidiary, UpdateSubsidiary } from "../../models/Subsidiary";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

function applyPatch<T>(original: T, updates: Partial<T>): T {
    return { ...original, ...updates };
}

export const updateSubsidiary = async (id: string, data: Partial<UpdateSubsidiary>) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        console.log('Updating Subsidiary');

        const subsidiary = getSubsidiary();

        const orginalSubsidiary = {
            parent_company_id: subsidiary.parent_company_id,
            branch_id: subsidiary.branch_id,
            logo_id: subsidiary.logo_id,
            name: subsidiary.name,
            street: subsidiary.street,
            house_number: subsidiary.house_number,
            postal_code: subsidiary.postal_code,
            city: subsidiary.city,
            province: subsidiary.province,
            country: subsidiary.country,
            selected_labels: subsidiary.selected_labels,
            opening_hours: {},
            is_active: false
        }

        const updatedSubsidiary = applyPatch(orginalSubsidiary, data);

        console.log(updatedSubsidiary);

        const url = `/subsidiaries/${id}`;
        const response = await api.put(url, updatedSubsidiary, config);
        console.log(response);
        return response;
    } catch (error) {
        console.error('Failed to update subsidiary:', error);
        throw error;
    }
}

export const patchSubsidiaryDB = async (data: PatchSubsidiary) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    const subsidiary = getSubsidiary();

    try {
        console.log('Patching Subsidiary');

        const url = `/subsidiaries/${subsidiary.id}`;
        const response = await api.patch(url, data, config);
        return response.data as Subsidiary;
    } catch (error) {
        console.error('Failed to patch subsidiary:', error);
        throw error;
    }
}

export const addSubsidiary = async (subsidiary: CreateSubsidiary): Promise<Subsidiary> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        console.log('Adding Subsidiary');
        const response = await api.post('/subsidiaries/', subsidiary, config);
        return response.data as Subsidiary;
    } catch (error) {
        console.error('Failed to add subsidiary:', error);
        throw error;
    }
}