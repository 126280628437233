import React from 'react';
import PageLayout from '../../components/Site/PageLayout/PageLayout';
import './FAQView.css';
import FAQItem from '../../components/Site/FAQView/FAQItem/FAQItem';

const faqData = [
    {
        question: 'Wat is "10-Things"?',
        answer: '"10-Things" is een innovatieve briefing app ontworpen voor de horeca gebaseerd op de methodieken van horecagoeroe Wouter Verkerk. De app helpt horecabedrijven bij hun dagelijkse briefing door een dagelijks rapport te genereren op basis van 10 focuspunten van wat er die dag in hun horecazaak is gebeurd. De 10 focuspunten van de dag kan de manager zelf inplannen of kan je door ons laten doen door op de laat je inspireren knop te klikken. Handig!'
    },
    {
        question: 'Hoe kan ik "10-Things" integreren in mijn horecazaak?',
        answer: 'Integratie is heel eenvoudig. Neem contact met ons op voor een demo en wij helpen je met de installatie en implementatie in je bedrijf. Twijfel niet om ons een bericht te sturen! We helpen je graag met extra informatie en het beantwoorden van je vragen.'
    },
    {
        question: 'Welke functionaliteiten biedt "10-Things"?',
        answer: '"10-Things" biedt functies zoals dagelijkse rapporten, meldingen, teamcommunicatie, taakbeheer, en meer om de dagelijkse operatie van je horecazaak te stroomlijnen.'
    },
    {
        question: 'Hoe gebruiksvriendelijk is "10-Things"?',
        answer: 'We weten dat je heel druk bent. "10-Things" is daarom ontworpen met een intuïtieve interface die gemakkelijk te gebruiken is, zelfs voor degenen zonder technische achtergrond. Heb je geen tijd om zelf je ‘things’ in te voeren? Druk dan op de ‘laat je inspireren’ knop zodat wij voor jou de ‘things’ kunnen genereren!'
    },
    {
        question: 'Is er een mobiele versie van "10-Things"?',
        answer: 'Ja, "10-Things" is beschikbaar als mobiele app voor iOS, zodat je altijd en overal toegang hebt tot je dagelijkse briefings. Tevens kan je de app gebruiken via je webbrowser op je laptop.'
    },
    {
        question: 'Hoe kan "10-Things" mijn team helpen beter te communiceren?',
        answer: 'Met functies zoals real-time meldingen en een overzichtelijk dashboard, helpt "10-Things" om de communicatie en samenwerking binnen je team te verbeteren.'
    },
    {
        question: 'Kan ik "10-Things" aanpassen aan de specifieke behoeften van mijn bedrijf?',
        answer: 'Ja, "10-Things" biedt diverse aanpassingsmogelijkheden om te voldoen aan de unieke eisen en processen van jouw horecazaak. Heb je meerdere vestigingen? Neem contact met ons op zodat we jullie kunnen helpen met een op maat gemaakt plan en offerte.'
    },
    {
        question: 'Hoe veilig zijn de gegevens die ik in "10-Things" invoer?',
        answer: 'De veiligheid van je gegevens is onze topprioriteit. "10-Things" maakt gebruik van geavanceerde beveiligingsprotocollen om ervoor te zorgen dat al je informatie veilig en beschermd is.'
    },
    {
        question: 'Bieden jullie ondersteuning voor "10-Things"?',
        answer: 'Ja, we bieden uitgebreide ondersteuning inclusief training, handleidingen, en een klantenservice die klaarstaat om je te helpen met al je vragen.'
    },
    {
        question: 'Hoe kan ik een demo aanvragen van "10-Things"?',
        answer: 'Je kunt een demo aanvragen door contact met ons op te nemen via onze website of door ons een e-mail te sturen. We plannen graag een demonstratie in om je de voordelen van "10-Things" te laten zien.'
    }
];

const FAQView: React.FC = () => {
    return (
        <PageLayout>
            <section className="faq-section">
                <div className="faq-container-div">
                    <div className="faq-title-area-div">
                        <h1 className="faq-title-h1">Veelgestelde vragen</h1>
                    </div>
                    <div className="faq-questions-area-div">
                        {faqData.map((item, index) => (
                            <FAQItem key={index} question={item.question} answer={item.answer} />
                        ))}
                    </div>
                </div>
            </section>
        </PageLayout>
    );
};

export default FAQView;