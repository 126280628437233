import React, { useState } from 'react';
import './LoginView.css';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { loginUser } from '../../api/auth/user.auth';
import { getSubsidiary } from '../../api/auth/user-info';
import usePageTracking from '../../utils/usePageTracking';

const LoginView: React.FC = () => {
    usePageTracking();
    const navigate = useNavigate();
    // const location = useLocation();

    // const queryParams = new URLSearchParams(location.search);
    // const ref = queryParams.get('ref');

    const [isLoading, setIsLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let isValid = true;

        if (!username) {
            setEmailError('Je moet wel een e-mailadres invullen!');
            isValid = false;
        } else {
            if (!emailRegex.test(username)) {
                setEmailError('Je moet wel een geldig e-mailadres invullen!');
                isValid = false;
            } else {
                setEmailError('');
            }
        }

        if (!password) {
            setPasswordError('Je moet wel een wachtwoord invullen!');
            isValid = false;
        } else {
            setPasswordError('');
        }

        if (isValid) {
            // Do something with the data
            setIsLoading(true);

            loginUser(username, password)
                .then(data => {
                    console.log(data);
                    // Handle the data...
                    if (getSubsidiary() === null) {
                        navigate('/portaal/voeg-vestiging-toe');
                    } else {
                        navigate('/portaal/dashboard');

                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    // Handle the error...
                    console.log(error);
                    setIsLoading(false);
                    setPasswordError('Je e-mail of wachtwoord komt niet overeen!');

                });
        }

    };

    return (
        <div>
            <Helmet>
                <title>10-Things | Inloggen</title>
            </Helmet>
            <a href="/">
                <button className="main-goback-butt">
                    <span className="main-goback-icon-span material-symbols-outlined">arrow_back</span>
                </button>
            </a>
            <div id='inloggen-wrapper-div'>
                <div id='inloggen-inner-page'>
                    <div id='inloggen-login-view-div'>
                        <div id="inloggen-login-logo-area">
                            <img src="../assets/img/website-10things-logo.png" alt="" className="inloggen-login-logo" />
                        </div>

                        <div id="inloggen-login-form-area-div">
                            <form id="inloggen-login-form" onSubmit={handleSubmit}>
                                <div id='emailinput-bar' className="inloggen-login-form-input-bar">
                                    <label htmlFor="login-email-input" className="inloggen-login-label">E-mail</label>
                                    <input type="text" className="inloggen-login-input" id='login-email-input' value={username} onChange={handleUsernameChange} />
                                    <div id='login-email-err' className="login-form-error-field">{emailError}</div>
                                </div>

                                <div id='passwordinput-bar' className="inloggen-login-form-input-bar">
                                    <label htmlFor="login-password-input" className="inloggen-login-label">Wachtwoord</label>
                                    <input type="password" className="inloggen-login-input" id='login-password-input' value={password} onChange={handlePasswordChange} />
                                    <div id='login-password-err' className="login-form-error-field">{passwordError}</div>
                                </div>

                                <div className="inloggen-login-form-input-bar">
                                    <div className="inloggen-login-form-left-area">
                                        <label id="login-remember-me-checkbox">
                                            <input type="checkbox" id="login-remember-checkbox-input" />
                                            <span className="checkmark"></span>
                                            <span className="checkbox-text">Onthoud mij</span>
                                        </label>
                                    </div>
                                    <div className="inloggen-login-form-right-area">
                                        <Link to='/wachtwoord-vergeten'>
                                        <p className="inloggen-login-forget-pass-txt-p">Wachtwoord vergeten?</p>
                                        </Link>
                                    </div>
                                </div>

                                <div className="inloggen-login-form-input-bar">
                                    <button type="submit" className="inloggen-login-button">
                                        {isLoading ? (
                                            <span className="progress"></span>
                                        ) : (
                                            <div id="login-txt-div">Aanmelden</div>
                                        )}
                                    </button>
                                </div>
                                
                                <div className="inloggen-login-form-inpur-bar">
                                    <Link to={"/register"}>
                                        <button className="inloggen-register-btn"><div id="login-txt-div">Geen account? Registreer hier</div></button>
                                    </Link>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginView;