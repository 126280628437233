import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './HamburgerMenu.css';
import { Link, useLocation } from 'react-router-dom';

const HamburgerMenu: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    

    return (
        <div className="hamburger-menu-container">
            <motion.div
                className="hamburger-icon"
                onClick={toggleMenu}
                initial={false}
                animate={isOpen ? 'open' : 'closed'}
                variants={{
                    open: { rotate: 90 },
                    closed: { rotate: 0 },
                }}
            >
                <motion.span
                    className="line"
                    variants={{
                        open: { rotate: 45, y: 6 },
                        closed: { rotate: 0, y: 0 },
                    }}
                />
                <motion.span
                    className="line"
                    variants={{
                        open: { opacity: 0 },
                        closed: { opacity: 1 },
                    }}
                    transition={{ duration: 0.1 }}
                />
                <motion.span
                    className="line"
                    variants={{
                        open: { rotate: -45, y: -14 },
                        closed: { rotate: 0, y: 0 },
                    }}
                />
            </motion.div>
            <motion.div
                className="menu"
                initial={false}
                animate={isOpen ? 'open' : 'closed'}
                variants={{
                    open: { opacity: 1, y: 0, display: 'block' },
                    closed: { opacity: 1, y: -450, transitionEnd: { display: 'none' } },
                }}
                transition={{ duration: 0.4 }}
            >
                <ul>
                    <li>
                        <Link to="/#pricing">
                        <button className="hamburger-menu-btn" type='button'>
                            Prijzen overzicht
                        </button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/register">
                            <button className="hamburger-menu-btn" type='button'>
                                Start je proefperiode
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact">
                            <button className="hamburger-menu-btn" type='button'>
                                Contact
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/login">
                        <button className="hamburger-login-btn" type='button'>
                            Inloggen naar de app
                        </button>
                        </Link>
                    </li>
                </ul>
            </motion.div>
        </div>
    );
};

export default HamburgerMenu;