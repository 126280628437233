import React from 'react';
import './RecapView.css';
import { Textarea } from '@nextui-org/react';

interface RecapViewProps {
    // Add any props you need for the RecapView component
    recap: string;
    setRecap: (value: string) => void;
    recapError: string;
}

const RecapView: React.FC<RecapViewProps> = ({ recap, setRecap, recapError }) => {
    // Add your component logic and JSX here

    return (
        <div className="de-briefing-recap-step">
            <Textarea
                value={recap}
                onChange={(e) => setRecap(e.target.value)}
                variant="bordered"
                label="Rapportage van de shift"
                className='de-briefing-recap-textarea'
                minRows={15}
                maxRows={15}
                errorMessage={recapError}
                isInvalid={recapError !== ''}
            />
        </div>
    );
};

export default RecapView;