import React, { useEffect, useRef, useState } from 'react';
import './ActionOptionsOverlay.css';
import { Action } from '../../../../models/Action';
import { deleteDBAction } from '../../../../api/actions/actionsService';

interface ActionOptionsOverlayProps {
    onClose: () => void;
    x: number;
    y: number;
    selectedActionOptionsAction?: Action;
    deleteAction: (action_id: number) => void;
}

const ActionOptionsOverlay: React.FC<ActionOptionsOverlayProps> = ({ onClose, x, y, selectedActionOptionsAction, deleteAction }) => {
    const [adjustedX, setAdjustedX] = useState(-1000); // Initially off-screen
    const [adjustedY, setAdjustedY] = useState(-1000); // Initially off-screen
    const optionsRef = useRef<HTMLDivElement | null>(null);

    // Adjust the position if it's too close to the edge of the screen
    useEffect(() => {
        const optionsWidth = optionsRef.current?.offsetWidth || 0;
        const optionsHeight = optionsRef.current?.offsetHeight || 0;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const margin = 40; // Margin value

        if (x + optionsWidth + margin > windowWidth) {
            setAdjustedX(windowWidth - optionsWidth - margin); // Add margin to the right
        } else if (x - margin < 0) {
            setAdjustedX(margin); // Add margin to the left
        } else {
            setAdjustedX(x);
        }

        if (y + optionsHeight + margin > windowHeight) {
            setAdjustedY(y - optionsHeight - margin); // Add margin to the bottom
        } else if (y - margin < 0) {
            setAdjustedY(margin); // Add margin to the top
        } else {
            setAdjustedY(y);
        }
    }, [x, y]);

    const handleDelete = async () => {	
        console.log('Delete action:', selectedActionOptionsAction);
        try {
            onClose();
            deleteAction(selectedActionOptionsAction!.id);
            const deletingAction = await deleteDBAction(selectedActionOptionsAction!.id);
            console.log('Deleted action:', deletingAction);
        } catch (err) {
            console.error('Error deleting action:', err);
        }
    };

    return (
        <div className="options-overlay" onClick={onClose}>
            <div ref={optionsRef} className="options" style={{ left: adjustedX, top: adjustedY }} onClick={(event) => event.stopPropagation()}>
                <ul className="options-listing-ul">
                    {selectedActionOptionsAction?.is_planned && selectedActionOptionsAction?.is_pinned ? <li className="options-listing-li"><span className="options-li-icon-span material-symbols-outlined">keep_off</span> <span className="options-li-txt-span">Losmaken</span></li> : <li className="options-listing-li"><span className="options-li-icon-span material-symbols-outlined">keep</span> <span className="options-li-txt-span">Vastzetten</span></li>}
                    {selectedActionOptionsAction?.from_template ? <li className="options-listing-li"><span className="options-li-icon-span material-symbols-outlined">save</span> <span className="options-li-txt-span">Opslaan</span></li> : null}
                    <li className="options-listing-li delete" onClick={handleDelete}><span className="options-li-icon-span material-symbols-outlined">delete</span> <span className="options-li-txt-span">Verwijderen</span></li>
                </ul>
            </div>
        </div>
    );
};

export default ActionOptionsOverlay;