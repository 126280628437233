import React, { useState } from 'react';
import './AddVIPModal.css';
import { Input, Slider, Textarea } from '@nextui-org/react';
import { addVipDB } from '../../../../api/vip/VipService';
import { CreateVip, Vip } from '../../../../models/Vip';
import { getSubsidiary } from '../../../../api/auth/user-info';
import { Shifts } from '../../../../enums/Shifts';
import { Shift } from '../../../../models/Shift';

interface AddVIPModalProps {
    onClose: () => void;
    activeShift: Shift;
    addVip: (vip: Vip) => void;
}

const AddVIPModal: React.FC<AddVIPModalProps> = (props) => {

    const [name, setName] = useState<string>('');
    const [amountOfGuests, setAmountOfGuests] = useState<string>('1');
    const [details, setDetails] = useState<string>('');
    const [tableNumber, setTableNumber] = useState<string>('');

    const [nameError, setNameError] = useState<string>('');
    const [amountOfGuestsError, setAmountOfGuestsError] = useState<string>('');
    const [tableNumberError, setTableNumberError] = useState<string>('');

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const handleSubmit = async () => {
        let error = false;
        if (name.length === 0) {
            setNameError('Naam is verplicht');
            error = true;
        } else {
            setNameError('');
        }

        if (amountOfGuests.length === 0) {
            setAmountOfGuestsError('Aantal gasten is verplicht');
            error = true;
        } else {
            setAmountOfGuestsError('');
        }

        if (tableNumber.length === 0) {
            setTableNumberError('Tafelnummer is verplicht');
            error = true;
        } else {
            setTableNumberError('');
        }

        if (!error) {
            const subsidiary = getSubsidiary();
            const formatteddate = new Date().toISOString().split('T')[0];
            const vip: CreateVip = {
                name: name,
                amount_of_guests: parseInt(amountOfGuests, 10),
                date: formatteddate,
                tablenumber: tableNumber,
                comments: details,
                subsidiary_id: subsidiary.id,
                shift_id: props.activeShift.id,
                is_active: true,
            }
            try {
                const responseAddingVIP = await addVipDB(vip);
                console.log(responseAddingVIP);
                props.addVip(responseAddingVIP);
            } catch (error) {
                console.error('Failed to add VIP:', error);
            } finally {
                props.onClose();
            }
        }

    }

    return (
        <div className='add-vip-modal-overlay' onClick={props.onClose}>
            <div className="add-vip-modal-wrapper">
                <div className="add-vip-modal-container" onClick={stopPropagation}>
                    <div className="add-vip-modal-top-area">
                        <div className="add-vip-modal-top-inner-title-area">
                            <h2 className="add-vip-modal-title">Voeg VIP toe</h2>
                        </div>
                        <div className="add-vip-modal-top-inner-close-area">
                            <button className="add-vip-modal-close-btn" onClick={props.onClose} type='button'>
                                <span className="material-symbols-outlined">close</span>
                            </button>
                        </div>
                    </div>
                    <div className="add-vip-modal-content">
                        <Input className='add-vip-modal-input' type="text" variant="bordered" value={name} onChange={(e) => setName(e.target.value)} label="Naam" errorMessage={nameError} isInvalid={nameError !== ''} />
                        <Input
                            className='add-vip-modal-input'
                            type="number"
                            value={amountOfGuests}
                            onChange={(e) => {
                                setAmountOfGuests(e.target.value);
                            }}
                        variant="bordered"
                        label="Aantal gasten"
                        errorMessage={amountOfGuestsError}
                        isInvalid={amountOfGuestsError !== ''}
                        />
                        <Input className='add-vip-modal-input' type='text' variant="bordered" label="Tafelnummer" value={tableNumber} onChange={(e) => setTableNumber(e.target.value)} errorMessage={tableNumberError} isInvalid={tableNumberError !== ''} />
                        <Textarea className='add-vip-modal-input' variant="bordered" label="Bijzonderheden" minRows={10} value={details} onChange={(e) => setDetails(e.target.value)} />
                        <div className="add-vip-modal-controls-area">
                            <button className="add-vip-modal-add-btn" type='button' onClick={handleSubmit}>Voeg toe</button>
                            <button className="add-vip-modal-cancel-btn" type='button' onClick={props.onClose}>Annuleren</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddVIPModal;