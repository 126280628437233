import { CreateEvent, Event } from "../../models/Event";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

export const fetchEvents = async () => {
    const subsidiary = getSubsidiary();

    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    const url = `/events/get_events_by_subsidiary/${subsidiary.id}/`;
    const response = await api.get(url, config);
    return response.data;

};

export const addEventDB = async (event: CreateEvent): Promise<Event> => {
    const subsidiary = getSubsidiary();
    if (!subsidiary) {
        throw new Error('Subsidiary is null');
    }
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/events/`;
        const response = await api.post(url, event, config);
        return response.data;
    } catch (error) {
        console.error('Failed to add event:', error);
        throw error;
    }
};
