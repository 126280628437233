import React, { useEffect, useRef, useState } from 'react';
import { Vip, VipBriefing } from '../../../../models/Vip';
import './VipWidgetItem.css';

interface VipWidgetItemProps {
    vip: VipBriefing | Vip;
    onVipOptionsClick: (event: React.MouseEvent, vip: VipBriefing | Vip) => void;
}

const VipWidgetItem: React.FC<VipWidgetItemProps> = ({ vip, onVipOptionsClick }) => {
    return (
        <li className="vip-item-li">
            <div className="vip-top">
                <p className="vip-tablenumber-txt-p">TAFEL {vip.tablenumber}</p>
                <p className="vip-guests-txt-p">{vip.amount_of_guests} PAX</p>
                <button className="vip-options-btn" onClick={(e) => onVipOptionsClick(e, vip)}>
                    <span className="material-symbols-outlined">more_vert</span>
                </button>
            </div>
            <h4 className="vip-name-title-h4">{vip.name}</h4>
            <p className="vip-comment-txt-p">{vip.comments}</p>
        </li>
    );
};

export default VipWidgetItem;