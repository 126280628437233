import React from 'react';
import './DeletePopup.css';

interface DeletePopupProps {
    title: string;
    text: string;
    onDelete: () => void;
    onClose: () => void;
}

const DeletePopup: React.FC<DeletePopupProps> = ({ title, text, onDelete, onClose }) => {

    const stopPropagation = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    }

    return (
        <div className="delete-popup-overlay" onClick={onClose}>
            <div className="delete-popup-wrapper">
                <div className="delete-popup" onClick={(e) => stopPropagation(e)}>
                    <h2 className='delete-popup-title-h2'>{title}</h2>
                    <p className="delete-popup-txt-p">{text}</p>
                    <div className="delete-popup-btns-area">
                        <button className='delete-popup-btn' onClick={onClose}>Annuleren</button>
                        <button className='delete-popup-btn delete' onClick={onDelete}>Verwijderen</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeletePopup;