import axios from 'axios';

// Define headers using environment variables
const headers = {
    'access_token': process.env.REACT_APP_API_KEY
};

const devEnv = true;

var apiURL = process.env.REACT_APP_API_URL;

if (devEnv) {
    apiURL = process.env.REACT_APP_DEV_BACKEND_URL;
}

// Create axios instance
const access_token = axios.create({
    baseURL: apiURL,
    headers: headers
});

// Add request interceptor
access_token.interceptors.request.use(config => {
    // Dynamically set the access token for each request
    config.headers['access_token'] = process.env.REACT_APP_API_KEY;
    return config;
}, error => {
    // Handle request errors here
    console.error('Request error:', error);
    return Promise.reject(error);
});

export default access_token;