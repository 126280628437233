import React from 'react';
import { Link } from 'react-router-dom';
import './NavBarUnderButton.css';

interface NavBarUnderButtonProps {
    icon: string;
    href?: string;
    alignRight?: boolean;
    toggleNav: () => void;
}

const NavBarUnderButton: React.FC<NavBarUnderButtonProps> = ({icon, href, alignRight, toggleNav}) => {

    const handleClick = () => {
        toggleNav();
    };

    return (
        <div>
            {href ? (
                <Link to={href} onClick={toggleNav}>
                    <button className={`main-portal-menu-under-btn ${alignRight ? 'right' : ''}`} type='button'>
                        <span id="main-portal-menu-under-btn-icon" className="material-symbols-outlined">{icon}</span>
                    </button>
                </Link>
            ) : (
                <button className={`main-portal-menu-under-btn ${alignRight ? 'right' : ''}`} onClick={handleClick} type='button'>
                    <span id="main-portal-menu-under-btn-icon" className="material-symbols-outlined">{icon}</span>
                </button>
            )}
        </div>
    );
};

export default NavBarUnderButton;