import { Menu, MenuCreate } from "../../models/Menu";
import api from "../auth/api";
import { getSubsidiary } from "../auth/user-info";

export const fetchMenuBySubsidiary = async (): Promise<Menu[]> => {
    const subsidiary = getSubsidiary();
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };
    try {
        const url = `/menus/get_menus_by_subsidiary/${subsidiary.id}/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch menu:', error);
        throw error;
    }
};

export const createMenu = async (menu: MenuCreate): Promise<Menu> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/menus/`;
        const response = await api.post(url, menu, config);
        return response.data;
    } catch (error) {
        console.error('Failed to create menu:', error);
        throw error;
    }
};

export const deleteMenuDB = async (menuId: number) => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/menus/${menuId}/`;
        await api.delete(url, config);
    } catch (error) {
        console.error('Failed to delete menu:', error);
        throw error;
    }
};