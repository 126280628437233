import React from 'react';
import './CalendarTopBar.css';

interface CalendarTopBarProps {
    handleNextButtonClick: () => void;
    handlePreviousButtonClick: () => void;
    toggleAgendaType: () => void;
    currentMonth: Date;
    setOpenAddEventModal: (open: boolean) => void;
}

const CalendarTopBar: React.FC<CalendarTopBarProps> = (props) => {
    // Implement the component logic here

    const monthNames = ["januari", "februari", "maart", "april", "mei", "juni",
        "juli", "augustus", "september", "oktober", "november", "december"];
    var month = monthNames[props.currentMonth.getMonth()];
    var year = props.currentMonth.getFullYear();

    return (
        // JSX code for the component UI goes here
        <div id="calendarview-controls-area">

            <div id="calendarview-month-txt-area">
                <button id="calendar-week-picker-btn" onClick={props.toggleAgendaType}>
                    <span className="calendar-week-picker-span material-symbols-outlined">calendar_month</span>
                </button>
                <h3 id="calendarview-month-txt-h3">{month}, {year}</h3>
            </div>
            <div id="calendarview-center-area">

            </div>
            <div id="calendarview-right-controls-area">
                <button className="calendarview-add-event-btn" type='button' onClick={() => props.setOpenAddEventModal(true)}>
                    <span className="calendarview-add-event-icon-span material-symbols-outlined">add</span>
                    <span className='calendarview-add-event-txt-span'>Voeg toe</span>
                </button>
                <div id="calendarview-controls-container">
                    <button id="calendarview-right-control-btn" className='calendarview-top-btn' onClick={props.handlePreviousButtonClick}>
                        <span className="calendarview-icon-span material-symbols-outlined">chevron_left</span>
                    </button>
                    <button id="calendarview-today-control-btn">
                        Vandaag
                    </button>
                    <button id="calendarview-left-control-btn" onClick={props.handleNextButtonClick}>
                        <span className="material-symbols-outlined">chevron_right</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CalendarTopBar;