import { Slider } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import './GradeView.css';
import { ReportKpi } from '../../../../../../models/ReportKpi';
import { KPITypes } from '../../../../../../enums/KPITypes';

interface GradeViewProps {
    reportKpis: ReportKpi[];
    kpiValues: any;
    setKpiValues: (kpiValues: (prevGrades: { [key: string]: number }) => { [key: string]: number }) => void;
    totalGrade: number;
    setTotalGrade: (totalGrade: number) => void;
}

const GradeView: React.FC<GradeViewProps> = ({ reportKpis, kpiValues, setKpiValues, totalGrade, setTotalGrade }) => {
    useEffect(() => {
        const initialGrades: { [key: string]: number } = {};
        reportKpis.forEach(kpi => {
            initialGrades[kpi.id] = 1;
        });
        setKpiValues(initialGrades as any);
    }, [reportKpis]);

    useEffect(() => {
        const total = Object.values(kpiValues).reduce((acc, grade) => acc as any + grade as any, 0);
        setTotalGrade((total as any * 2) / Object.keys(kpiValues).length);
    }, [kpiValues]);

    const handleGradeChange = (id: string, value: number) => {
        setKpiValues((prevGrades: any) => ({
            ...prevGrades,
            [id]: value
        }));
    };

    return (
        <div className='de-briefing-grade-step'>
            <div className="de-briefing-grade-top-area">
                <h2 className='de-briefing-title-h2'>Geef cijfers voor de shift</h2>
                <div className="de-briefing-total-grade">
                    <p className='de-briefing-total-grade-p'>{totalGrade.toFixed(1)}</p>
                </div>
            </div>
            {reportKpis.map((kpi, index) => {
                if (kpi.kpi_type === KPITypes.GRADE) {
                    return (
                        <Slider
                            key={index}
                            label={kpi.label}
                            step={1}
                            maxValue={5}
                            minValue={1}
                            defaultValue={kpiValues[kpi.id] || 1}
                            value={kpiValues[kpi.id]}
                            onChange={(value) => handleGradeChange(kpi.id.toString(), value as number)}
                            showSteps={true}
                            className='de-briefing-slider'
                        />
                    );
                }
                return null;
            })}
        </div>
    );
};

export default GradeView;