import React, { useState } from 'react';
import { Branch } from '../../../../../models/Branch';
import { Select, SelectItem } from '@nextui-org/react';

interface SelectBranchProps {
    branches: Branch[];
    selectedBranch: string | undefined;
    setSelectedBranch: (branchId: string) => void;
    errorSelectedBranch: string;
}

const SelectBranch: React.FC<SelectBranchProps> = ({ branches, selectedBranch, setSelectedBranch, errorSelectedBranch }) => {

    const onChangeBranch = (e: any) => {
        setSelectedBranch(e.target.value);
    }

    return (
        <div>
            <label htmlFor="branch"><h1 className="add-subsidiary-form-title-h1">Selecteer jouw branch</h1></label>
            <p className="add-subsidiary-form-txt-p">Om specifieke kenmerken van jouw horecazaak te kunnen begrijpen willen wij weten in welke branch jouw zaak fungeert.</p>
            <Select
                variant='bordered'
                label="Selecteer je branch"
                className="max-w bg-white"
                value={selectedBranch}
                onChange={onChangeBranch}
                selectedKeys={[selectedBranch!]}
                isInvalid={errorSelectedBranch !== ''}
                errorMessage={errorSelectedBranch}
            >
                {branches.map((branch) => (
                    <SelectItem key={branch.id} value={branch.id} >
                        {branch.title}
                    </SelectItem>
                ))}
            </Select>
        </div>
    );
};

export default SelectBranch;