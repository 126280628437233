import React from 'react';
import './ActionItemPreBriefing.css';
import { Action, ActionPreBriefing } from '../../../../../../models/Action';

interface ActionItemPreBriefingProps {
    action: any;
    backgroundColor: string;
    onClick: (index: number) => void;
    index: number;
}

const ActionItemPreBriefing: React.FC<ActionItemPreBriefingProps> = (props) => {
    // Implement the ActionItemPreBriefing component logic here

    return (
        <li className="thinglist-listing-li" style={{ backgroundColor: props.backgroundColor }} onClick={() => props.onClick(props.index)}>
            <div className="thinglist-title-area-div">
                <h2 className="thinglist-title-h2">{props.action.title}</h2>
            </div>
            <hr />
            <div className="thinglist-info-area-div">
                <p className="thinglist-info-p">{props.action.description}</p>
            </div>
        </li>
    );
};

export default ActionItemPreBriefing;