import React from 'react';
import './SettingsNavBar.css';
import { useNavigate } from 'react-router-dom';

interface SettingsNavBarProps {
    // Add any props you need here
    selectedSetting: string;
    selectedMenu: Boolean;
    setSelectedMenu: React.Dispatch<React.SetStateAction<Boolean>>;
}

const SettingsNavBar: React.FC<SettingsNavBarProps> = ( props ) => {
    const navigate = useNavigate();

    const selectedMenu = (menu: string) => {
        props.setSelectedMenu(!props.selectedMenu);
        navigate({ pathname: '/portaal/instellingen', search: `?m=${menu}` });
    }

    return (
        <nav id="settings-nav-bar" className={props.selectedMenu ? 'mobile-menu-closed' : undefined}>
            <ul id="settings-nav-bar-ul">
                <li className="settings-nav-bar-li">
                    <p className="settings-title-txt-p">Account</p>
                </li>
                <li className="settings-nav-bar-li">
                    <button className="settings-nav-btn" type='button' onClick={() => selectedMenu('account')}>
                        <span className="settings-nav-icon-span material-symbols-outlined">person</span>
                        <span className='settings-nav-txt-span'>Accountgegevens</span>
                    </button>
                </li>
                <li className="settings-nav-bar-li">
                    <p className="settings-title-txt-p">Vestiging</p>
                </li>
                <li className="settings-nav-bar-li">
                    <button className="settings-nav-btn" type='button' onClick={() => selectedMenu('subsidiary')}>
                        <span className="settings-nav-icon-span material-symbols-outlined">apartment</span>
                        <span className='settings-nav-txt-span'>Vestiginggegevens</span>
                    </button>
                </li>
                <li className="settings-nav-bar-li">
                    <button className="settings-nav-btn" type='button' onClick={() => selectedMenu('report-ontvangers')}>
                        <span className="settings-nav-icon-span material-symbols-outlined">group</span>
                        <span className='settings-nav-txt-span'>Rapport ontvangers</span>
                    </button>
                </li>
                <li className="settings-nav-bar-li">
                    <p className="settings-title-txt-p">Abonnement</p>
                </li>
                <li className="settings-nav-bar-li">
                    <button className="settings-nav-btn" type='button' onClick={() => selectedMenu('subscription')}>
                        <span className="settings-nav-icon-span material-symbols-outlined">card_membership</span>
                        <span className='settings-nav-txt-span'>Kies een abonnement</span>
                    </button>
                </li>
                <li className="settings-nav-bar-li">
                    <button className="settings-nav-btn" type='button' onClick={() => selectedMenu('invoices')}>
                        <span className="settings-nav-icon-span material-symbols-outlined">receipt</span>
                        <span className='settings-nav-txt-span'>Facturen</span>
                    </button>
                </li>
                {/* <li className="settings-nav-bar-li">
                    <p className="settings-title-txt-p">Ondersteuning</p>
                </li>
                <li className="settings-nav-bar-li">
                    <button className="settings-nav-btn" type='button' onClick={() => selectedMenu('feedback')}>
                        <span className="settings-nav-icon-span material-symbols-outlined">feedback</span>
                        <span className='settings-nav-txt-span'>Feedback</span>
                    </button>
                </li> */}
            </ul>
        </nav>
    );
};

export default SettingsNavBar;