import React from 'react';
import './CalendarView.css';
import ThingsList from '../../ThingsList/ThingsList';

interface CalendarViewProps {
    currentWeek: Date;
    selectedDate: Date;
    setSelectedDate: (date: Date) => void;
    refreshActions: () => void;
    onClickAddButton: (thingtype: any) => void;
    thingTypes: any[];
    actions: any[];
    onClickAction: (action: any) => void;
    onActionOptionsClick: (event: React.MouseEvent, action: any) => void;
}

const CalendarView: React.FC<CalendarViewProps> = (props) => {
    // Add your component logic and JSX here

    const getStartOfWeek = (date: Date) => {
        const startOfWeek = new Date(date);
        const dayOfWeek = startOfWeek.getDay();
        const difference =
            dayOfWeek === 0
                ? -6 // for Sunday
                : 1 - dayOfWeek; // for other days
        startOfWeek.setDate(startOfWeek.getDate() + difference);
        return startOfWeek;
    };

    const selectDay = (date: Date) => {
        console.log('Selected date:', date.toISOString().slice(0, 10));
        props.setSelectedDate(date);
        props.refreshActions();
    };

    const renderDaysOfWeek = () => {
        const daysOfWeek = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'];
        const startDate = getStartOfWeek(new Date(props.currentWeek));
        const days: JSX.Element[] = [];

        for (let i = 0; i < 7; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + i);
            const dayOfWeek = daysOfWeek[(currentDate.getDay() + 6) % 7]; // Updated this line
            const dayOfMonth = currentDate.getDate();
            const isActive = currentDate.toISOString().slice(0, 10) === props.selectedDate.toISOString().slice(0, 10);
            days.push((
                <li className="calendarview-daysoftweek-item" key={i}>
                    <button className={`calendarview-dayofweek-btn ${isActive ? 'active' : ''}`} onClick={() => selectDay(currentDate)}>
                        <p className="calendarview-dayofweek-span">{dayOfWeek}</p>
                        <p className="calendarview-dateofweek-span">{dayOfMonth}</p>
                    </button>
                </li>
            ));
        }

        return days;
    };

    return (
        <div id='calendarview-container'>
            <div id="calendarview-daysofweek-area">
                <ul id="calendarview-daysoftweek-listing-ul">
                    {renderDaysOfWeek()}
                </ul>
            </div>
            <ThingsList thingtypes={props.thingTypes} actions={props.actions} onClickAddButton={props.onClickAddButton} onClickAction={props.onClickAction} onActionOptionsClick={props.onActionOptionsClick} />
        </div>
    );
};

export default CalendarView;