import React from 'react';
import { Note } from '../../../../models/Note';
import './NoteItem.css';

interface NoteItemProps {
    note: Note;
    onClickNote: (note: Note) => void;
    onClickNoteDone: (event: React.MouseEvent, id: number) => void;
}

const NoteItem: React.FC<NoteItemProps> = ({ note, onClickNote, onClickNoteDone}) => {
    console.log('NoteType: ', note.note_type);
    let note_type_class;
    let note_title;
    if(note.note_type  === 1) {
        note_title = 'Wat ging goed?';
        note_type_class = 'note-type-1';
    } else if(note.note_type === 2) {
        note_title = 'Wat kon beter?';
        note_type_class = 'note-type-2';
    } else if(note.note_type === 3) {
        note_title = 'Welke bijzonderheden?';
        note_type_class = 'note-type-3';
    }

    return (
        <li className={`notes-list-item ${note_type_class}`} onClick={() => onClickNote(note)}>
            <h3 className="notes-list-item-title-h3">{note_title}</h3>
            <p className="notes-list-item-txt-p" dangerouslySetInnerHTML={{ __html: note.note_txt!.replace(/\n/g, '<br />') }} />
            {/* <div className="notes-under-bar-options-area">
                <p className="notes-list-item-created-at">
                    {new Date(note.created_at).toLocaleString('nl', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
                </p>
                <button className="note-markdone-btn" onClick={(event) => onClickNoteDone(event, note.id)}>
                    <span className="note-markdone-icon-span material-symbols-outlined">done</span>
                </button>
            </div> */}
        </li>
    );
};

export default NoteItem;