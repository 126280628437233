import React from 'react';
import PortaalPageLayout from '../../../components/Portaal/PageLayout/PageLayout';

interface EmployeeViewProps {
    toggleNav: () => void;
    isNavOpen: boolean;
}

const EmployeeView: React.FC<EmployeeViewProps> = ( props ) => {
    return (
        <div>
            <PortaalPageLayout activePage='planning' isNavOpen={props.isNavOpen} toggleNav={props.toggleNav}>
                Test
            </PortaalPageLayout>
        </div>
    );
};

export default EmployeeView;