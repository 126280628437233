import React from 'react';
import './AddMailerReport.css';
import { Input } from '@nextui-org/react';
import { addReportMaillingList } from '../../../../../../api/report_mailinglist/reportmaillinglistService';
import { getSubsidiary } from '../../../../../../api/auth/user-info';
import { ReportMailinglist } from '../../../../../../models/ReportMailinglist';

interface AddMailerReportProps {
    // Add any props you need for the component here
    onClose: () => void;
    addReceiver: (receiver: ReportMailinglist) => void;
}

const AddMailerReport: React.FC<AddMailerReportProps> = ( props ) => {
    // Add your component logic here

    const [fname, setFname] = React.useState<string>('');
    const [errorFname, setErrorFname] = React.useState<string>('');
    const [lname, setLname] = React.useState<string>('');
    const [errorLname, setErrorLname] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [errorEmail, setErrorEmail] = React.useState<string>('');
    const [phone, setPhone] = React.useState<string>('');
    const [errorPhone, setErrorPhone] = React.useState<string>('');

    const handleSubmit = async () => {
        let error = false;

        if (fname === '') {
            setErrorFname('Voornaam is verplicht');
            error = true;
        } else {
            setErrorFname('');
        }

        if (lname === '') {
            setErrorLname('Achternaam is verplicht');
            error = true;
        } else {
            setErrorLname('');
        }

        if (email === '') {
            setErrorEmail('Email is verplicht');
            error = true;
        } else {
            setErrorEmail('');
        }

        if (phone === '') {
            setErrorPhone('Telefoon is verplicht');
            error = true;
        } else {
            setErrorPhone('');
        }

        if (!error) {

            console.log('Error:', error);

            const subsidiary = getSubsidiary();

            const response = await addReportMaillingList({
                subsidiary_id: subsidiary.id,
                first_name: fname,
                last_name: lname,
                email: email,
                phone_number: phone
            });

            if (response) {
                props.addReceiver(response);
                props.onClose();
            }
        }
    }

    return (
        <div>
            <div className="addmailer-input-area">
                <div className="addmailer-half-area">
                    <Input label="Voornaam" name='fname' className='addmailer-input' value={fname} onChange={(event) => setFname(event.target.value)} isInvalid={errorFname ? true : false} errorMessage={errorFname} />
                </div>
                <div className="addmailer-half-area">
                    <Input label="Achternaam" name='lname' className='addmailer-input' value={lname} onChange={(event) => setLname(event.target.value)} isInvalid={errorFname ? true : false} errorMessage={errorLname} />
                </div>
            </div>
            <div className="addmailer-input-area">
                <Input label="Email" name='email' className='addmailer-input' value={email} onChange={(event) => setEmail(event.target.value)} isInvalid={errorEmail ? true : false} errorMessage={errorEmail} />
            </div>
            <div className="addmailer-input-area">
                <Input label="Telefoon" name='phone' className='addmailer-input' value={phone} onChange={(event) => setPhone(event.target.value)}isInvalid={errorPhone ? true : false} errorMessage={errorPhone} />
            </div>
            <div className="addmailer-controls-area">
                <button className="addmailer-submit-btn" onClick={handleSubmit}>Voeg toe</button>
                <button className="addmailer-cancel-btn" type='button' onClick={props.onClose}>Annuleren</button>
            </div>
        </div>
    );
};

export default AddMailerReport;