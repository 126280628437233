import React from 'react';
import './ReportNotesList.css';

interface ReportNotesListProps {
    notes: any[];
    onClickNote: (note: any) => void;
}

const ReportNotesList: React.FC<ReportNotesListProps> = (props) => {
    return (
        <div className='reportnotes-wrapper'>
            <h1 className='reportnotes-title-h1'>Notities</h1>
            <ul className='reportnotes-listing-ul'>
                {props.notes.map((note, index) => (
                    <li key={index} onClick={() => props.onClickNote(note)} className='reportnotes-listing-li'>
                        <div className="reportnotes-content-area">
                            <p className='reportnotes-info-p'>{note.note_txt}</p>
                        </div>

                        <div className="reportnotes-info-area">
                            <p className="reportnotes-info-created-at">{new Date(note.created_at).toLocaleString('nl', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ReportNotesList;