import { ReportKpi } from "../../models/ReportKpi";
import api from "../auth/api";

export const fetchReportKpis = async (): Promise<ReportKpi[]> => {
    const config = {
        headers: {
            'Accept': 'application/json',
        }
    };

    try {
        const url = `/report_kpis/`;
        const response = await api.get(url, config);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch reportkpis:', error);
        throw error;
    }
};