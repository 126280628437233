import React from 'react';
import './UnsubscribePopUp.css';

interface UnsubscribePopUpProps {
    onClose: () => void;
    handleUnsubscribe: () => void;
}

const UnsubscribePopUp: React.FC<UnsubscribePopUpProps> = ({ onClose, handleUnsubscribe }) => {
    return (
        <div className="unsubscribe-popup">
            <p className='unsubscribe-txt-p'>Als je je abonnement annuleerd kun je niet meer gebruik maken van 10-Things tot het eindigen van je huidige abonnement.</p>
            <div className="unsubscribe-grid">
                <button className='unsubscribe-btn cancel' onClick={onClose}>Annuleren</button>
                <button className='unsubscribe-btn unsubscribe' onClick={handleUnsubscribe}>Opzeggen</button>
            </div>
        </div>
    );
};

export default UnsubscribePopUp;