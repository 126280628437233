import React from 'react';
import './CompleteThingModal.css';
import { Input, Tab, Tabs, Textarea } from '@nextui-org/react';
import { updateAction } from '../../../../api/actions/actionsService';

interface CompleteThingModalProps {
    // Add any props you need for the component here
    action: any;
    closeCompleteThingModal: () => void;
}

const CompleteThingModal: React.FC<CompleteThingModalProps> = (props) => {
    // Add your component logic here

    var statusTxt = props.action.completed_on === null ? 'todo' : 'done';

    console.log(props.action);

    console.log(props.action.completed_on);

    const [status, setStatus] = React.useState(statusTxt);

    const [comments, setComments] = React.useState(props.action.comments);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        var completed_on = props.action.completed_on;

        if (status === 'done') {
            completed_on = new Date().toISOString().split('T')[0];
        }

        if (status === 'todo') {
            completed_on = null;
        }
        
        console.log(completed_on);
        props.action.completed_on = completed_on;
        props.action.comments = comments;
        console.log('Action:', props.action);

        updateAction(props.action);
        props.closeCompleteThingModal();
    };

    const changeStatus = (status: string) => {
        console.log('Status:', status);
        setStatus(status);
    }

    return (
        // Add your JSX code for the modal here
        <div>
            <form onSubmit={handleSubmit}>
                <div className="action-status-area">
                    <Tabs color='primary' aria-label="Action status" radius="full" onSelectionChange={(key) => changeStatus(key.toString())} selectedKey={status}>
                        <Tab key="todo" title="To Do" />
                        <Tab key="done" title="Done" />
                    </Tabs>
                </div>
                <Input className='title-input' type="title" label="Titel" value={props.action.title} variant='bordered' readOnly={true} />
                <Textarea
                    className='completething-description-input'
                    variant='bordered'
                    label="Beschrijving"
                    value={props.action.description}
                    readOnly={true}
                />
                <div className="thingtype-showcase-div">
                    <span className="thingtype-showcase-color-span" style={{ backgroundColor: props.action.thing_type.color }}>

                    </span>
                    <p className="thingtype-showcase-type-txt-p">{props.action.thing_type.singular}</p>
                </div>
                <Textarea
                    className='completething-opmerkingen-input'
                    variant='bordered'
                    label="Opmerkingen"
                    minRows={10}
                    maxRows={10}
                    value={comments}
                    onChange={(event) => setComments(event.target.value)}
                />
                <div className="completething-controls-div">
                    <button className='completething-submit-control-btn' type="submit">Opslaan</button>
                    <button onClick={props.closeCompleteThingModal} className='completething-cancel-control-btn' type="button">Annuleren</button>
                </div>
            </form>
        </div>
    );
};

export default CompleteThingModal;