import React, { useState } from 'react';
import './AddEventBriefingModal.css';
import { CalendarDate, DatePicker, DateValue, Input, Switch, Textarea } from '@nextui-org/react';
import { CreateEvent } from '../../../../../models/Event';
import { EventType } from '../../../../../enums/EventType';
import { AnyCalendarDate } from '@internationalized/date';
import { Subsidiary } from '../../../../../models/Subsidiary';
import { getSubsidiary } from '../../../../../api/auth/user-info';

interface AddEventBriefingModalProps {
    onClose: () => void;
    addEvent: (event: CreateEvent) => void;
}

const AddEventBriefingModal: React.FC<AddEventBriefingModalProps> = (props) => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const handleAddEvent = async () => {
        let error = false;
        // Validatie kan hier worden toegevoegd
        if (name === '') {
            setNameError('Naam is verplicht.');
            error = true;
        } else {
            setNameError('');
        }


        if (!error) {
            const subsidiary: Subsidiary = getSubsidiary();

            const date = new Date();
            const dateString = date.toISOString().split('T')[0];
            const event: CreateEvent = {
                name: name,
                date: dateString,
                event_type: EventType.CREATED_EVENTS,
                subsidiary_id: subsidiary.id,
            }

            if (description !== '') {
                event.description = description;
            }

            props.addEvent(event);
            props.onClose();
        }
    };

    return (
        <div className='add-event-pre-briefing-modal-overlay' onClick={props.onClose}>
            <div className="add-event-pre-briefing-modal-wrapper">
                <div className="add-event-pre-briefing-modal-container" onClick={stopPropagation}>
                    <div className="add-event-pre-briefing-modal-top-area">
                        <div className="add-event-pre-briefing-modal-top-inner-title-area">
                            <h2 className="add-event-pre-briefing-modal-title">Voeg evenement toe</h2>
                        </div>
                        <div className="add-event-pre-briefing-modal-top-inner-close-area">
                            <button className="add-event-pre-briefing-modal-close-btn" onClick={props.onClose} type='button'>
                                <span className="material-symbols-outlined">close</span>
                            </button>
                        </div>
                    </div>
                    <div className="add-event-pre-briefing-modal-content">
                        <Input
                            className='add-event-pre-briefing-modal-input'
                            type="text"
                            variant="bordered"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                if (e.target.value !== '') setNameError('');
                            }}
                            label="Naam"
                            errorMessage={nameError}
                            isInvalid={nameError !== ''}
                        />
                        <Textarea
                            className='add-event-pre-briefing-modal-input'
                            variant="bordered"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            label="Beschrijving"
                            minRows={3}
                        />

                        <div className="add-event-pre-briefing-modal-controls-area">
                            <button
                                className="add-event-pre-briefing-modal-add-btn"
                                type='button'
                                onClick={handleAddEvent}
                            >
                                Voeg toe
                            </button>
                            <button
                                className="add-event-pre-briefing-modal-cancel-btn"
                                type='button'
                                onClick={props.onClose}
                            >
                                Annuleren
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEventBriefingModal;
