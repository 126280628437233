import React from 'react';
import { Link } from 'react-router-dom';
import './NavBarItem.css';

interface NavBarItemProps {
    title: string;
    icon: string;
    href: string;
    toggleNav: () => void;
}

const NavBarItem: React.FC<NavBarItemProps> = ({ title, icon, href, toggleNav }) => {
    return (
        <li className="main-portal-menu-item-li">
            <Link to={href} onClick={toggleNav}>
                <div className="main-portal-menu-list-item-div">
                    <div className="main-portal-menu-item-icon-area">
                        <span className="main-portal-menu-item-icon-span material-symbols-outlined">{icon}</span>
                    </div>
                    <div className="main-portal-menu-item-txt-area">
                        <p className="main-portal-menu-item-txt-p">{title}</p>
                    </div>
                </div>
            </Link>
        </li>
    );
};

export default NavBarItem;