import React, { useEffect } from 'react';
import './SubsidiarySettings.css';
import '../InnerViews.css';
import { Select, SelectItem } from '@nextui-org/react';
import { getSubsidiary, updateSubsidiaryInfo } from '../../../../../api/auth/user-info';
import { updateSubsidiary } from '../../../../../api/subsidiary/subsidiaryService';
import { fetchBranches } from '../../../../../api/branches/branchesService';
import { fetchLabels } from '../../../../../api/labels/labelsService';
import LogoUploadModal from '../../../../../components/Portaal/Settings/LogoUploadModal/LogoUploadModal';
import { Branch } from '../../../../../models/Branch';
import { Helmet } from 'react-helmet-async';

interface SubsidiarySettingsProps {
    setSelectedEditOverlay: (value: string) => void;
    setModalIsOpen: (value: boolean) => void;
    setModalTitle: (value: string) => void;
    selectedMenu: Boolean;
    setSelectedMenu: React.Dispatch<React.SetStateAction<Boolean>>;
}

const SubsidiarySettings: React.FC<SubsidiarySettingsProps> = (props) => {
    // Add your component logic here

    const [editingArea, setEditingArea] = React.useState<string>('');

    const handleEditClick = (type: string) => {
        setEditingArea(type);
    };

    const handleActionClick = (action: string) => {
        props.setSelectedEditOverlay(action);
        props.setModalIsOpen(true);
    };

    const subsidiary = getSubsidiary();
    const subsidiaryProfileName = subsidiary.name[0].toUpperCase();

    const [name, setName] = React.useState<string>(subsidiary.name);
    const [branch, setBranch] = React.useState<any>(subsidiary.branch_id);
    const [street, setStreet] = React.useState<string>(subsidiary.street);
    const [houseNumber, setHouseNumber] = React.useState<string>(subsidiary.house_number);
    const [postalCode, setPostalCode] = React.useState<string>(subsidiary.postal_code);
    const [city, setCity] = React.useState<string>(subsidiary.city);
    const [province, setProvince] = React.useState<string>(subsidiary.province);
    const [country, setCountry] = React.useState<string>(subsidiary.country);

    const handleSaveClick = () => {
        if (editingArea === 'naam') {
            updateSubsidiary(subsidiary.id, { name: name });
            updateSubsidiaryInfo({ name: name });
            setEditingArea('');
        } else if (editingArea === 'branche') {
            updateSubsidiary(subsidiary.id, { branch_id: branch });
            updateSubsidiaryInfo({ branch_id: branch });
            setEditingArea('');
        } else if (editingArea === 'adres') {
            updateSubsidiary(subsidiary.id, { street: street, house_number: houseNumber, postal_code: postalCode, city: city, province: province, country: country });
            updateSubsidiaryInfo({ street: street, house_number: houseNumber, postal_code: postalCode, city: city, province: province, country: country });
            setEditingArea('');
        }
    };

    const [branches, setBranches] = React.useState<Branch[]>([]);

    useEffect(() => {
        const loadBranches = async () => {
            try {
                const branches = await fetchBranches();
                setBranches(branches);
            } catch (err) {
                console.error('Failed to fetch branches:', err);
            } finally {
                console.log('Done fetching branches');
            }
        };

        loadBranches();
    }, []);

    const [labels, setLabels] = React.useState<any[]>([]);

    useEffect(() => {
        const loadLabels = async () => {
            try {
                const labels = await fetchLabels();
                console.log(labels);
                setLabels(labels);
            } catch (error) {
                console.error('Error fetching labels:', error);
            } finally {
                console.log('Done fetching labels');
            }
        };

        loadLabels();
    }, []);

    const selectedBranch = branches.filter((branch) => branch.id === subsidiary.branch_id)[0];

    const selectedLabelsArr = JSON.parse(subsidiary.selected_labels);

    console.log(selectedLabelsArr);

    // console.log(selectedBranch.title);

    // console.log(branches);
    // console.log(subsidiary);

    const handleCloseBtnClick = () => {
        props.setSelectedMenu(!props.selectedMenu);
    };

    const [isUploadLogo, setIsUploadLogo] = React.useState<boolean>(false);

    const onClose = () => {
        setIsUploadLogo(false);
    }

    const handleUploadLogoClick = () => {
        setIsUploadLogo(true);
    }

    const [hasLogo, setHasLogo] = React.useState<boolean>(subsidiary.logo_id !== null);
    const [logo, setLogo] = React.useState<string>(subsidiary.logo ? subsidiary.logo.file_src : '');


    return (
        <div className='accountsettings-container'>
            <Helmet>
                <title>10-Things | Portaal | Vestiging Instellingen</title>
            </Helmet>
            {isUploadLogo && <LogoUploadModal setHasLogo={setHasLogo} setLogo={setLogo} onClose={onClose} />}
            <div className="settings-top-bar">
                <h2 className="settings-title-h2">Vestiginggegevens</h2>
                {editingArea !== '' &&
                    <div className="settings-controls-area">
                        <button className="settings-cancel-btn" type='button' onClick={() => handleEditClick('')}>
                            Annuleren
                        </button>
                        <button className="settings-save-btn" type='button' onClick={handleSaveClick}>
                            Opslaan
                        </button>
                    </div>
                }
                <button className="settings-mobile-close-btn" type='button' onClick={handleCloseBtnClick}>
                    <span className="material-symbols-rounded settings-mobile-close-icon-span">close</span>
                </button>
            </div>
            <ul className="settings-listing-ul">
                <li className="settings-item-li">
                    <div className="settings-left-area">
                        <div className="subsidiary-logo-inner-wrapper">
                            <div className="subsidiary-logo-profile-div">
                                <div className="subsidiary-logo-change-div">
                                    <div className="subsidiary-logo-change-wrapper">
                                        <button className="subsidiary-logo-change-btn" type='button' onClick={handleUploadLogoClick}>
                                            <span className="subsidiary-logo-change-icon-span material-symbols-outlined">edit</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="subsidiary-logo-content-div">
                                    {logo ? <img src={logo} alt="Logo" className="subsidiary-logo-img" /> : <p className="subsidiary-no-logo-txt-p">{subsidiaryProfileName}</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="settings-right-area">

                    </div>
                </li>
                <li className="settings-item-li">
                    {editingArea !== 'naam' && <div className="settings-static-values">
                        <div className="settings-left-area">
                            <p className="settings-type-txt-p">Vestigingnaam</p>
                            <p className="settings-value-txt-p">{subsidiary.name}</p>
                        </div>

                        <div className="settings-right-area">
                            <button className="settings-edit-btn" type='button' onClick={() => handleEditClick('naam')}>
                                Wijzigen
                            </button>
                        </div>
                    </div>}
                    {editingArea === 'naam' && <div className="settings-edit-values">
                        <div className="settings-edit-bar">
                            <div className="settings-edit-label-area">
                                <label className='settings-edit-label' htmlFor="naam">Vestigingnaam</label>
                            </div>
                            <div className="settings-edit-input-area">
                                <input type="text" className="settings-edit-input" id='naam' name="naam" value={name} onChange={(event) => setName(event.target.value)} />
                            </div>
                        </div>
                    </div>}
                </li>
                {selectedBranch && <li className="settings-item-li">
                    {editingArea !== 'branche' && <div className="settings-static-values">
                        <div className="settings-left-area">
                            <p className="settings-type-txt-p">Branche</p>
                            <p className="settings-value-txt-p">{selectedBranch.title}</p>
                        </div>

                        <div className="settings-right-area">
                            <button className="settings-edit-btn" type='button' onClick={() => handleEditClick('branche')}>
                                Wijzigen
                            </button>
                        </div>
                    </div>}

                    {editingArea === 'branche' && <div className="settings-edit-values">
                        <div className="settings-edit-bar">
                            <div className="settings-edit-label-area">
                                <label className='settings-edit-label' htmlFor="branche">Branche</label>
                            </div>
                            <div className="settings-edit-input-area">
                                <Select aria-label="Selecteer branch" className='select-input' variant='flat' defaultSelectedKeys={[branch]} onChange={(e) => setBranch(e.target.value)}>
                                    {branches.filter((branche) => branche.parent_id !== null).map((branch, index) => (
                                        <SelectItem key={branch.id} value={branch.id}>
                                            {branch.title}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>}
                </li>}
                <li className="settings-item-li">
                    {editingArea !== 'adres' && <div className="settings-static-values">
                        <div className="settings-left-area">
                            <p className="settings-type-txt-p">Adresgegevens</p>
                            <p className="settings-value-txt-p">Straat: {subsidiary.street}</p>
                            <p className="settings-value-txt-p">Huisnummer: {subsidiary.house_number}</p>
                            <p className="settings-value-txt-p">Postcode: {subsidiary.postal_code}</p>
                            <p className="settings-value-txt-p">Plaats: {subsidiary.city}</p>
                            <p className="settings-value-txt-p">Provincie: {subsidiary.province}</p>
                            <p className="settings-value-txt-p">Land: {subsidiary.country}</p>
                        </div>

                        <div className="settings-right-area">
                            <button className="settings-edit-btn" type='button' onClick={() => handleEditClick('adres')}>
                                Wijzigen
                            </button>
                        </div>
                    </div>}

                    {editingArea === 'adres' && <div className="settings-edit-values">
                        <div className="settings-edit-bar">
                            <div className="settings-edit-label-area">
                                <label className='settings-edit-label' htmlFor="branche">Straat</label>
                            </div>

                            <div className="settings-edit-input-area">
                                <input type="text" className="settings-edit-input" id='straat' name="straat" value={street} onChange={(event) => setStreet(event.target.value)} />
                            </div>
                        </div>

                        <div className="settings-edit-bar">
                            <div className="settings-edit-label-area">
                                <label className='settings-edit-label' htmlFor="huisnummer">Huisnummer</label>
                            </div>

                            <div className="settings-edit-input-area">
                                <input type="text" className="settings-edit-input" id='huisnummer' name="huisnummer" value={houseNumber} onChange={(event) => setHouseNumber(event.target.value)} />
                            </div>
                        </div>

                        <div className="settings-edit-bar">
                            <div className="settings-edit-label-area">
                                <label className='settings-edit-label' htmlFor="postcode">Postcode</label>
                            </div>

                            <div className="settings-edit-input-area">
                                <input type="text" className="settings-edit-input" id='postcode' name="postcode" value={postalCode} onChange={(event) => setPostalCode(event.target.value)} />
                            </div>
                        </div>

                        <div className="settings-edit-bar">
                            <div className="settings-edit-label-area">
                                <label className='settings-edit-label' htmlFor="plaats">Plaats</label>
                            </div>

                            <div className="settings-edit-input-area">
                                <input type="text" className="settings-edit-input" id='plaats' name="plaats" value={city} onChange={(event) => setCity(event.target.value)} />
                            </div>
                        </div>

                        <div className="settings-edit-bar">
                            <div className="settings-edit-label-area">
                                <label className='settings-edit-label' htmlFor="provincie">Provincie</label>
                            </div>

                            <div className="settings-edit-input-area">
                                <input type="text" className="settings-edit-input" id='provincie' name="provincie" value={province} onChange={(event) => setProvince(event.target.value)} />
                            </div>
                        </div>

                        <div className="settings-edit-bar">
                            <div className="settings-edit-label-area">
                                <label className='settings-edit-label' htmlFor="land">Land</label>
                            </div>

                            <div className="settings-edit-input-area">
                                <input type="text" className="settings-edit-input" id='land' name="land" value={country} onChange={(event) => setCountry(event.target.value)} />
                            </div>
                        </div>
                    </div>}
                </li>
                <li className="settings-item-li">
                    <div className="settings-static-values">
                        <div className="settings-left-area">
                            <p className="settings-type-txt-p">Labels</p>
                            <p className="settings-value-txt-p">{
                                selectedLabelsArr.map((label: any) => {
                                    const labelObj = labels.find((labelObj) => labelObj.id === label);
                                    return labelObj ? labelObj.value : '';
                                }).join(', ')
                            }</p>
                        </div>

                        <div className="settings-right-area">
                            <button className="settings-edit-btn" type='button' onClick={() => handleActionClick('labels')}>
                                Wijzig labels
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
            {editingArea !== '' &&
                <div className="mobile-settings-save-area">
                    <button className="settings-cancel-btn" type='button' onClick={() => handleEditClick('')}>
                        Annuleren
                    </button>
                    <button className="settings-save-btn" type='button' onClick={handleSaveClick}>
                        Opslaan
                    </button>
                </div>
            }
        </div>
    );
};

export default SubsidiarySettings;